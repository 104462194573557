@media all {
  .octf_tools_bar {
    position: fixed;
    right: -400px;
    top: 0;
    background-color: #fff;
    width: 400px;
    height: 100%;
    z-index: 10001;
    -webkit-transition: right 0.3s ease;
    -moz-transition: right 0.3s ease;
    transition: right 0.3s ease;
  }

  .octf_tools_bar__sidebar-cover {
    content: "";
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    cursor: url(https://livewp.site/wp/md/ewebot/wp-content/plugins/gt3-theme-tools-bar/assets/close.png) 16 16,
      default;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .octf_tools_bar .octf_tools_bar__icon_container {
    position: absolute;
    top: calc(40% - 88px);
    left: -45px;
    display: inline-block;
    box-shadow: -8px 8px 30px rgba(0, 0, 0, 0.07);
  }

  .octf_tools_bar__sidebar {
    position: absolute;
    right: -17px;
    top: 0;
    height: 100%;
    width: 417px;
    padding: 30px;
    background: #fff;
    border-left: 1px solid #dcdcdc;
    color: #111;
    font-size: 14px;
    line-height: 1.7;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .octf_tools_bar__icon {
    height: 45px;
    line-height: 45px;
    text-align: center;
    position: relative;
    background-color: #2f323a;
    color: #fff;
    cursor: pointer;
    left: 0;
    white-space: nowrap;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .octf_tools_bar__icon.octf_tools_bar__icon_sale {
    background: #f73859;
  }

  .octf_tools_bar__icon.octf_tools_bar__icon_side_bar {
    background: #384259;
  }

  .octf_tools_bar__icon.octf_tools_bar__icon_support {
    background-color: #7ac7c4;
  }

  .octf_tools_bar__icon.octf_tools_bar__icon_docs {
    background: #c4edde;
  }

  .octf_tools_bar__icon a,
  .octf_tools_bar__icon a:hover,
  .octf_tools_bar__icon a:focus,
  .octf_tools_bar__icon a:active,
  .octf_tools_bar__icon a:visited {
    color: #fff;
  }

  .octf_tools_bar__icon div.octf_tools_bar__icon_src {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 53px;
    border-radius: 0 !important;
    transition: opacity 0.2s ease;
    color: inherit;
  }

  .octf_tools_bar__icon div.octf_tools_bar__icon_src svg {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 21px;
    fill: currentColor;
  }

  .octf_tools_bar__icon.octf_tools_bar__icon_side_bar div.octf_tools_bar__icon_src {
    background-position: 28px -36px;
  }

  .octf_tools_bar__icon.octf_tools_bar__icon_sale div.octf_tools_bar__icon_src {
    background-position: -37px -4px;
  }

  .octf_tools_bar__icon.octf_tools_bar__icon_support div.octf_tools_bar__icon_src {
    background-position: -68px -4px;
  }

  .octf_tools_bar__icon.octf_tools_bar__icon_docs div.octf_tools_bar__icon_src {
    background-position: -4px 28px;
  }

  .octf_tools_bar__icon_tooltip {
    font-family: "Nunito Sans", Sans-serif;
    font-size: 13px;
    width: 130px;
    height: 45px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    transition: all 0.2s ease;
    padding: 0;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.24);
    border-radius: 0;
    display: block;
    float: right;
    color: inherit;
  }

  .octf_tools_bar__icon:hover .octf_tools_bar__icon_tooltip {
    visibility: visible;
    opacity: 1;
    right: 110%;
  }

  .octf_tools_bar__icon:hover {
    left: -130px;
  }

  .octf_toolbar_button__container {
    text-align: center;
    padding-bottom: 40px;
  }

  .octf_toolbar_button__container a {
    font-size: 16px;
    padding: 10px 20px;
    line-height: 1.5;
    background-color: #82b440;
    -webkit-box-shadow: 0 2px 0 #6f9a37;
    box-shadow: 0 2px 0 #6f9a37;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    border: none;
    border-radius: 4px;
    font-family: "Nunito Sans", Sans-serif;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
  }

  .octf_toolbar_button__container a,
  .octf_toolbar_button__container a:hover,
  .octf_toolbar_button__container a:focus,
  .octf_toolbar_button__container a:active,
  .octf_toolbar_button__container a:visited {
    text-decoration: none;
    outline: none;
    color: #fff;
  }

  .octf_tools_bar__sidebar_container a img {
    box-shadow: -8px 8px 30px rgba(0, 0, 0, 0.07);
    max-width: 100%;
  }

  .octf_tools_bar .svg-preloader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    opacity: 1;
    visibility: visible;
    transition: opacity ease-in-out 400ms, visibility ease-in-out 400ms;
  }

  @media only screen and (max-width: 600px) {
    .octf_tools_bar {
      width: 300px;
      right: -300px;
    }

    .octf_tools_bar__sidebar {
      width: 317px;
    }

    .octf_toolbar_button__container a {
      padding: 8px 10px;
    }

    .octf_tools_bar__icon_tooltip {
      display: none;
    }

    .octf_tools_bar__icon:hover {
      left: 0;
    }
  }

  .octf-toolbar_wrapper {
    margin-bottom: 44px;
  }

  .octf-toolbar_wrapper .octf-toolbar_container {
    margin-bottom: 20px;
  }

  .octf-toolbar_title {
    margin-bottom: 24px;
    position: relative;
    text-align: left;
  }

  .octf-toolbar_title_wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  .octf-toolbar_title_inner {
    font-size: 14px;
    color: #363439;
    border-radius: 5px;
    line-height: 28px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Nunito Sans";
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.15);
    padding: 0 9px;
    margin-right: 21px;
  }

  .octf-toolbar_line {
    position: relative;
    display: block;
    height: 1px;
    background: #e5e5e5;
    width: 100%;
    flex: 1;
  }

  .octf-toolbar_line:before {
    content: "";
    position: absolute;
    z-index: 1;
    height: 3px;
    width: 40px;
    background: #f73859;
    border-radius: 1.5px;
    right: 0;
    top: 50%;
    margin-top: -1.5px;
  }

  .octf-toolbar_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
  }

  .octf-toolbar__title {
    font-size: 16px;
    line-height: 1.85;
    font-weight: 700;
    color: #363439;
    font-family: "Nunito Sans", Sans-serif;
  }

  .octf-toolbar__price {
    font-size: 16px;
    font-weight: 700;
    color: #f73859;
    font-family: "Nunito Sans", Sans-serif;
  }

  .octf_tools_bar__sidebar_container a img {
    border-radius: 5px;
    box-shadow: 5px 4px 16px 0 rgba(0, 0, 0, 0.15);
    transition: 0.5s;
  }

  @media only screen and (max-width: 480px) {
    .octf_tools_bar__icon_tooltip {
      display: none;
    }

    .octf_tools_bar__icon:hover {
      left: 0;
    }
  }

  @media only screen and (max-width: 420px) {
    .octf_tools_bar {
      width: 250px;
      right: -250px;
    }

    .octf_tools_bar__sidebar {
      width: 267px;
    }
  }
}

@media all {

  [class^="ot-flaticon-"]:before,
  [class^="ot-flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
  }

  .ot-flaticon-magnifiying-glass:before {
    content: "\f109";
  }

  .ot-flaticon-close:before {
    content: "\f10f";
  }

  .ot-flaticon-right-arrow:before {
    content: "\f110";
  }

  .ot-flaticon-up-arrow:before {
    content: "\f111";
  }

  .ot-flaticon-left-arrow:before {
    content: "\f112";
  }

  .ot-flaticon-menu-of-three-lines:before {
    content: "\f116";
  }
}

@media all {
  img {
    border: 0;
  }

  body,
  figure {
    margin: 0;
  }

  figure,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  a {
    background-color: transparent;
    color: #337ab7;
    text-decoration: none;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  body {
    background-color: #fff;
  }

  img {
    vertical-align: middle;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  button,
  input {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button,
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  *,
  :after,
  :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
  }

  button,
  input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a:focus,
  a:hover {
    color: #23527c;
    text-decoration: underline;
  }

  a:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
  }

  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  [role="button"] {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }

  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }

  @media (min-width: 1230px) {
    .container {
      width: 1200px;
    }
  }

  .container:after,
  .container:before {
    content: " ";
    display: table;
  }

  .container:after {
    clear: both;
  }
}

@media all {

  .owl-carousel,
  .owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  .owl-carousel {
    display: none;
    width: 100%;
    z-index: 1;
  }

  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    
  }

  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  .owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
  }

  .owl-carousel .owl-dots.disabled,
  .owl-carousel .owl-nav.disabled {
    display: none;
  }

  .owl-carousel.owl-loaded {
    display: block;
  }

  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    background: 0 0;
    color: inherit;
    border: none;
  }

  .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-theme .owl-dots,
  .owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
  }

  .owl-theme .owl-nav {
    margin-top: 10px;
  }

  .owl-theme .owl-nav [class*="owl-"] {
    outline: none;
    color: #fff;
    font-size: 20px;
    line-height: 1;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
  }

  .owl-theme .owl-nav [class*="owl-"]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none;
  }

  .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px;
  }
}

@media all {
  body {
    margin: 0;
  }

  a {
    background-color: transparent;
  }

  img {
    border-style: none;
  }

  button,
  input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button,
  [type="button"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  [type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  body,
  button,
  input {
    color: #646e83;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    line-height: 1.7777777778;
    font-weight: 400;
    word-break: break-word;
    box-sizing: border-box;
  }

  h1,
  h2,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.2;
    margin: 0 0 20px;
    color: #223354;
  }

  h1 {
    font-size: 60px;
    font-weight: 700;
  }

  h2 {
    font-size: 48px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    margin: 0 0 20px;
  }

  i {
    font-style: italic;
  }

  .dflex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .title-link {
    display: inline;
    background-size: 0 2px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(0deg, #fff, currentColor);
  }

  .title-link:hover {
    background-size: 100% 2px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    background: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  ul {
    margin: 0 0 20px;
    padding-left: 18px;
  }

  ul {
    list-style: disc;
  }

  li>ul {
    margin-bottom: 0;
    margin-left: 1.5em;
  }

  .none-style {
    list-style: none;
    padding-left: 0;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  figure {
    margin: 1em 0;
  }

  .octf-btn {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    font-size: 16px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    padding: 18px 45px;
    line-height: 1.42857143;
    display: inline-block;
    margin-bottom: 0;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    font-weight: 500;
    text-align: center;
    background-color: #ff6b52;
    cursor: pointer;
    border: 1px solid transparent;
    color: #fff;
    outline: none;
  }

  .octf-btn:visited {
    color: blue;
  }

  /* .octf-btn:hover,
  .octf-btn:focus {
    background-color: #223354;
    color: #fff;
  } */

  .octf-btn-second {
    background-color: white;
    
  }

  input[type="text"],
  input[type="email"],
  input[type="search"] {
    color: #646e83;
    border: 1px solid #d1e0e5;
    padding: 17px 30px;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="search"]:focus {
    color: #646e83;
  }

  a {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    color: #1080d0;
    text-decoration: none;
  }

  a:visited {
    color: #1080d0;
  }

  a:hover,
  a:focus,
  a:active {
    color: #ff6b52;
    text-decoration: none;
  }

  a:focus {
    outline: 0;
  }

  a:hover,
  a:active {
    outline: 0;
  }

  .main-navigation ul {
    list-style: none;
    padding-left: 0;
  }

  .site-header {
    background: #fff;
    position: relative;
    z-index: 10;
  }

  .site-header.header-transparent {
    position: absolute;
    width: 100%;
    background: transparent;
    z-index: 999;
    box-shadow: none;
  }

  .site-header.header-transparent .elementor-section:not(.is-stuck, .ot-mega-menu, .ot-side-menu) {
    background-color: transparent !important;
  }

  .main-navigation {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: auto;
  }

  .main-navigation>ul {
    position: relative;
    display: flex;
    width: 100%;
    vertical-align: middle;
    height: 100%;
  }

  .main-navigation ul {
    font-weight: 500;
    list-style: none;
    margin: 0 0;
    padding: 0 0;
  }

  .main-navigation>ul>li {
    margin: 0 20px;
    padding: 27px 0;
    float: left;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }

  .main-navigation>ul>li:last-child {
    margin-right: 0;
  }

  .main-navigation ul>li>a {
    position: relative;
    display: inline-block;
    line-height: 35px;
    text-decoration: none;
    text-align: center;
    outline: none;
    color: #223354;
    white-space: nowrap;
    -webkit-transition: all 0.2s ease-out 0s;
    -moz-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
  }

  .main-navigation ul>li>a:hover {
    color: #ff6b52;
  }

  .main-navigation>ul>li>a:before {
    position: absolute;
    height: 2px;
    width: 0;
    bottom: -2px;
    left: 0;
    background: #ff6b52;
    content: "";
    display: block;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
  }

  .main-navigation>ul>li>a:hover:before {
    width: 20px;
  }

  .main-navigation ul>li.menu-item-has-children,
  .main-navigation>ul>li.is-mega-menu {
    position: relative;
  }

  .main-navigation ul>li.menu-item-has-children>a:after,
  .main-navigation>ul>li.is-mega-menu>a:after {
    margin-top: -3px;
    margin-left: 8px;
    content: "";
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    color: #223354;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
  }

  .main-navigation ul>li.menu-item-has-children:hover>a,
  .main-navigation>ul>li.is-mega-menu:hover>a {
    color: #ff6b52;
  }

  .main-navigation ul>li.menu-item-has-children:hover>a:after,
  .main-navigation>ul>li.is-mega-menu:hover>a:after {
    color: #ff6b52;
  }

  .main-navigation ul.sub-menu>li>a:after {
    color: #646e83;
    position: absolute;
    top: 12px;
    right: 30px;
  }

  .main-navigation ul ul.sub-menu {
    float: none;
    margin: 0 0;
    padding: 0 0;
    background-color: #fff;
    min-width: 250px;
    white-space: nowrap;
    padding: 15px 0;
    position: absolute;
    top: 100%;
    left: -30px;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    box-sizing: border-box;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.07);
  }

  .main-navigation ul li li {
    display: block;
    position: relative;
  }

  .main-navigation ul li li a {
    line-height: 36px;
    font-weight: 400;
    color: #646e83;
    text-align: left;
    display: block;
    padding: 2px 30px;
    position: relative;
    text-decoration: none;
    outline: none;
  }

  .main-navigation ul li li a:hover {
    background-color: rgba(255, 107, 82, 0.1);
    color: #ff6b52;
  }

  .main-navigation ul li ul ul.sub-menu {
    left: 100%;
    top: -15px;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
  }

  .main-navigation ul li:hover>ul {
    visibility: visible;
    opacity: 1;
  }

  .main-navigation ul>li:hover>ul {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }

  .main-navigation>ul>li.is-mega-menu ul {
    background: none;
    padding: 0;
    box-shadow: none;
  }

  .main-navigation>ul>li.is-mega-menu ul li {
    white-space: normal;
    padding: 0;
  }

  .main-navigation>ul>li.is-mega-menu ul li a {
    padding: 0;
    text-align: unset;
    display: inline-block;
  }

  .main-navigation>ul>li.is-mega-menu ul li a:hover {
    background-color: unset;
  }

  .main-navigation>ul>li ul.sub-mega-menu .elementor-top-section>div {
    max-height: calc(100vh - 220px);
    overflow: hidden;
    overflow-y: auto;
    margin-right: -17px;
  }

  @media only screen and (max-width: 1024px) {
    .site-header {
      background: #fff;
      -webkit-box-shadow: 2px 2px 15px 0 rgba(42, 67, 113, 0.15);
      -moz-box-shadow: 2px 2px 15px 0 rgba(42, 67, 113, 0.15);
      box-shadow: 2px 2px 15px 0 rgba(42, 67, 113, 0.15);
    }
  }

  .site-header:before,
  .site-header:after,
  .site-content:before,
  .site-content:after,
  .site-footer:before,
  .site-footer:after {
    content: "";
    display: table;
    table-layout: fixed;
  }

  .site-header:after,
  .site-content:after,
  .site-footer:after {
    clear: both;
  }

  .the-logo img {
    max-width: 180px;
  }

  .owl-theme .owl-nav {
    margin: 0;
  }

  .owl-theme .owl-nav i:before {
    font-size: 17px;
  }

  .owl-theme .owl-nav [class*="owl-"] {
    color: #223354;
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
    margin: 0;
    width: 60px;
    height: 60px;
    padding: 0;
    line-height: 60px;
  }

  .owl-theme .owl-nav .owl-prev,
  .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    left: 40px;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .owl-theme .owl-nav .owl-next {
    left: auto;
    right: 40px;
  }

  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    background-color: #fff;
  }

  .owl-theme .owl-nav [class*="owl-"]:hover {
    color: #fff;
    background-color: #1080d0;
  }

  .search-form {
    position: relative;
  }

  .search-form .search-field {
    width: 100%;
    padding: 0 60px 0 30px;
    height: 60px;
    line-height: 1;
    border-color: #d1e0e5;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .search-form .search-submit {
    border: none;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 50px;
    outline: none;
    text-align: center;
    vertical-align: middle;
    color: #646e83;
    background: #ff6b52;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .search-form .search-submit i {
    color: #fff;
  }

  .search-form .search-submit i:before,
  .search-form .search-submit i:after {
    font-size: 16px;
  }

  .search-form .search-submit:hover {
    background: #1080d0;
  }

  .ot-title-link h5 {
    margin-bottom: 15px;
  }

  .ot-title-link a {
    color: #223354;
  }

  .ot-title-link a:hover {
    color: #ff6b52;
  }

  .ot-icon-box__icon {
    position: relative;
    line-height: 1;
    display: inline-block;
    margin-bottom: 16px;
    font-size: 36px;
    text-align: center;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
  }

  .ot-icon-box__icon svg {
    display: block;
    width: 1em;
    height: 1em;
    fill: #1080d0;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
  }

  .ot-icon-box__content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .ot-icon-box__content .icon-box-title {
    display: block;
    color: #223354;
    word-break: normal;
    font-size: 24px;
    margin-bottom: 12px;
    line-height: 30px;
    font-weight: 500;
  }

  .ot-icon-box__content .icon-box-title a {
    color: #223354;
  }

  .ot-icon-box__content .icon-box-title a:hover {
    color: #ff6b52;
  }

  .ot-icon-box__content .icon-box-des {
    line-height: 30px;
    font-size: 16px;
  }

  .ot-icon-box__content .icon-box-divider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 12px;
    padding-bottom: 22px;
  }

  .ot-icon-box__content .icon-box-divider span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    direction: ltr;
    height: 1px;
    background: #d8dfff;
    width: 100%;
  }

  .ot-view-stacked .ot-icon-box__icon {
    padding: 0.5em;
    background-color: #ff6b52;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  @media (min-width: 768px) {
    .ot-position-left .ot-icon-box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .ot-position-left .ot-icon-box__icon {
      margin-bottom: 0;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }

    .ot-position-left .ot-icon-box {
      text-align: left;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .ot-position-left .ot-icon-box__icon {
      margin-right: 28px;
    }

    .ot-vertical-align-top .ot-icon-box {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .ot-vertical-align-middle .ot-icon-box {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }

  @media (max-width: 767px) {
    .ot-icon-box__icon {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .ot-image-box {
    text-align: left;
  }

  .ot-image-box__img {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 35px;
  }

  .ot-image-box__img img {
    width: 100%;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
  }

  .ot-image-box__content {
    width: 100%;
  }

  .ot-image-box__content .image-box-title {
    display: block;
    color: #223354;
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 30px;
    font-weight: 500;
  }

  .ot-image-box__content .image-box-title a {
    color: #223354;
  }

  .ot-image-box__content .image-box-title a:hover {
    color: #ff6b52;
  }

  .ot-counter {
    display: block;
  }

  .ot-counter span {
    font-weight: 500;
    color: #1080d0;
    font-size: 48px;
    line-height: 1;
  }

  .ot-counter h6 {
    color: #646e83;
    margin-top: 12px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 24px;
  }

  .ot-counter .number-wrapper,
  .ot-counter .title-desc-wrapper {
    word-break: normal;
  }

  .ot-client-logo-slider .client-logo {
    display: inline-block;
    width: 100%;
    opacity: 1;
    will-change: opacity, visibility;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  }

  .ot-client-logo-slider .client-hover-logo {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    will-change: opacity, visibility;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  }

  .ot-client-logo-slider .client_link {
    display: block;
    position: relative;
    line-height: 0;
    text-align: center;
  }

  .ot-client-logo-slider .client_link:hover .client-logo {
    opacity: 0;
    visibility: hidden;
  }

  .ot-client-logo-slider .client_link:hover .client-hover-logo {
    opacity: 1;
    visibility: visible;
  }

  .security-software .mc4wp-form-fields .main-form input[type="email"] {
    background-color: #fff;
    color: #646e83;
    height: 60px;
    width: calc(100% - 250px);
    margin-right: 250px;
    padding-right: 30px;
    border: none;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    box-shadow: rgba(31, 1, 84, 0.08) 32px 32px 29px 0;
  }

  .security-software .mc4wp-form-fields .main-form input[type="submit"] {
    width: 240px;
    background-color: #4316c4;
    color: #fff;
    font-weight: 600;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    box-shadow: rgba(31, 1, 84, 0.08) 32px 32px 29px 0;
  }

  .security-software .mc4wp-form-fields .main-form input[type="submit"]:hover {
    background-color: #0f1a51;
  }

  .security-software .mc4wp-form-fields .main-form p {
    display: none;
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .security-software .mc4wp-form-fields .main-form input[type="submit"] {
      width: 180px;
    }

    .security-software .mc4wp-form-fields .main-form input[type="email"] {
      width: calc(100% - 200px);
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 992px) {
    .security-software .mc4wp-form-fields .main-form input[type="email"] {
      width: 100%;
    }

    .security-software .mc4wp-form-fields .main-form input[type="submit"] {
      margin-top: 20px;
      position: relative;
    }
  }

  @media (max-width: 600px) {
    .security-software .mc4wp-form-fields .main-form input[type="email"] {
      width: 100%;
    }

    .security-software .mc4wp-form-fields .main-form input[type="submit"] {
      margin-top: 20px;
      position: relative;
    }
  }

  @media (max-width: 767px) {
    .ibox-inline-mobile.ot-position-left .ot-icon-box {
      display: -webkit-box;
      display: -ms-flexbox;
    }

    .ibox-inline-mobile.ot-position-left .ot-icon-box .ot-icon-box__icon {
      margin-right: 10px !important;
    }
  }

  body .elementor-widget:not(:last-child) {
    margin-bottom: 0;
  }

  div.elementor-widget-heading.elementor-widget-heading .elementor-heading-title {
    color: #223354;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    line-height: 1.2;
  }

  .elementor-widget-heading h1.elementor-heading-title {
    font-size: 60px;
  }

  .elementor-widget-heading h2.elementor-heading-title {
    font-size: 48px;
  }

  .elementor-widget-heading h5.elementor-heading-title {
    font-size: 24px;
  }

  div.elementor-widget-text-editor,
  .elementor-widget-icon-list li.elementor-icon-list-item,
  .elementor-widget-icon-list li.elementor-icon-list-item a {
    font-family: "DM Sans", sans-serif;
  }

  div.elementor-widget-text-editor,
  .elementor-widget-icon-list span.elementor-icon-list-text {
    color: #646e83;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
  }

  .elementor-default .elementor-widget-text-editor p:last-child {
    margin-bottom: 0;
  }

  .elementor-section>.elementor-column-gap-default>.elementor-column>.elementor-element-populated,
  .elementor-section>.elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ot-flex-column-horizontal .elementor-widget-wrap {
    flex-wrap: nowrap;
  }

  .ot-flex-column-horizontal .elementor-widget-wrap>.elementor-widget {
    display: flex;
    width: auto;
  }

  @media (max-width: 767px) {
    .elementor-widget-heading h2.elementor-heading-title {
      font-size: 34px;
    }
  }

  @media (min-width: 768px) {
    .elementor .elementor-section.elementor-section-boxed>.elementor-container {
      max-width: 750px;
    }

    .elementor-element .container {
      width: 720px;
      padding: 0;
    }
  }

  @media (min-width: 992px) {
    .elementor .elementor-section.elementor-section-boxed>.elementor-container {
      max-width: 970px;
    }

    .elementor-element .container {
      width: 940px;
      padding: 0;
    }
  }

  @media (min-width: 1230px) {
    .elementor .elementor-section.elementor-section-boxed>.elementor-container {
      max-width: 1200px;
    }

    .elementor-element .container {
      width: 1170px;
      padding: 0;
    }
  }

  .octf-cta-header {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .octf-cta-header .toggle_search {
    cursor: pointer;
  }

  .octf-cta-header .toggle_search i {
    color: #223354;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    min-width: 22px;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
  }

  .octf-cta-header .toggle_search i:before,
  .octf-cta-header .toggle_search i:after {
    font-size: 22px;
  }

  .octf-cta-header .toggle_search i:hover {
    color: #ff6b52;
  }

  .octf-cta-header .h-search-form-field {
    display: none;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(34, 51, 84, 0.5);
  }

  .octf-cta-header .h-search-form-field .search-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    
  }

  .octf-cta-header .h-search-form-field .h-search-form-inner {
    padding: 45px 0 60px;
    background: #fff;
    box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.1);
  }

  .octf-cta-header .h-search-form-field .h-search-form-inner input {
    background: transparent;
    color: #223354;
    font-size: 30px;
    font-weight: 500;
    padding: 10px 60px 10px 0;
    height: 60px;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid rgba(34, 51, 84, 0.2);
  }

  .octf-cta-header .h-search-form-field .h-search-form-inner button {
    background: transparent;
    height: 60px;
    width: auto;
    bottom: 0;
    top: auto;
    text-align: right;
  }

  .octf-cta-header .h-search-form-field .h-search-form-inner button i {
    color: #223354;
  }

  .octf-cta-header .h-search-form-field .h-search-form-inner button i:before {
    font-size: 22px;
  }

  .octf-cta-header .h-search-form-field .h-search-form-inner>i {
    position: absolute;
    top: 20px;
    right: 40px;
    line-height: 1;
    color: #d3d6dd;
    cursor: pointer;
  }

  .octf-cta-header .h-search-form-field .h-search-form-inner ::placeholder {
    color: #223354;
  }

  .header-mobile {
    display: none;
  }

  .mmenu-toggle button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    color: #223354;
  }

  .mmenu-toggle button i:before {
    font-size: 28px;
  }

  .mmenu-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 310px;
    height: 100vh;
    background: #fff;
    overflow-x: hidden;
    z-index: 9999;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
  }

  .mmenu-wrapper .mmenu-inner {
    top: 0;
    height: 100%;
    width: 327px;
    padding: 20px 52px 35px 35px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .mmenu-wrapper .mmenu-close {
    display: block;
    margin-bottom: 10px;
    color: #223354;
  }

  .mmenu-wrapper .mobile_mainmenu {
    margin: 0;
    padding: 0;
  }

  .mmenu-wrapper .mobile-nav {
    min-width: 240px;
  }

  .mmenu-wrapper .mobile_mainmenu ul {
    position: relative;
    margin: 0;
    padding: 0;
    margin-left: 15px;
  }

  .mmenu-wrapper .mobile_mainmenu ul {
    display: none;
  }

  .mmenu-wrapper .mobile_mainmenu li {
    position: relative;
    list-style: none;
  }

  .mmenu-wrapper .mobile_mainmenu li a {
    padding: 10px 34px 10px 0;
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #223354;
    border-bottom: 1px solid rgba(34, 51, 84, 0.1);
  }

  .mmenu-wrapper .mobile_mainmenu>li.menu-item-has-children .arrow {
    color: #223354;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 34px;
    height: 43px;
    font-size: 19px;
    line-height: 43px;
    text-align: center;
    box-sizing: border-box;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
  }

  .site-overlay {
   
    background: transparent;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    z-index: -1;
  }

  @media only screen and (max-width: 1024px) {
    .header-desktop {
      display: none;
    }

    .header-mobile {
      display: block;
    }
  }

  .side-panel {
    width: 545px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    overflow-x: hidden;
    z-index: 9999;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
  }

  .side-panel .side-panel-block {
    padding: 90px 85px 90px 85px;
    height: 100%;
    width: calc(100% + 17px);
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .side-panel .side-panel-close {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    color: #192032;
    font-size: 20px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    cursor: pointer;
    z-index: 9999;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    text-align: center;
  }

  .mc4wp-form-fields .main-form {
    position: relative;
  }

  .mc4wp-form-fields .main-form input[type="email"] {
    height: 60px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    border: none;
    padding: 0 205px 0 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .mc4wp-form-fields .main-form input[type="email"]::placeholder {
    color: #fff;
  }

  .mc4wp-form-fields .main-form input[type="submit"] {
    background-color: #4316C4;
    border: none;
    outline: none;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 175px;
    height: 60px;
    font-size: 16px;
    text-align: center;
    color: #edf9fd;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    border-top-left-radius: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -webkit-border-top-right-radius: 30px;
    -webkit-border-bottom-right-radius: 30px;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-topright: 30px;
    -moz-border-radius-bottomright: 30px;
    -moz-border-radius-bottomleft: 0;
  }

  .mc4wp-form-fields .main-form input[type="submit"]:hover {
    background-color: #ff6b52;
  }

  .mc4wp-form-fields .main-form p label {
    font-size: 14px;
    line-height: 20px;
    color: #b2b6be;
    display: block;
    margin-top: 20px;
    max-width: 430px;
  }

  .mc4wp-form-fields .main-form [type="checkbox"]:not(:checked),
  .mc4wp-form-fields .main-form [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }

  .mc4wp-form-fields .main-form [type="checkbox"]:not(:checked)+span,
  .mc4wp-form-fields .main-form [type="checkbox"]:checked+span {
    position: relative;
    padding-left: 43px;
    display: inline-block;
    cursor: pointer;
  }

  .mc4wp-form-fields .main-form [type="checkbox"]:not(:checked)+span:before,
  .mc4wp-form-fields .main-form [type="checkbox"]:checked+span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 24px;
    height: 24px;
    border: 1px solid #aaa;
    background: #fff;
    border-radius: 4px;
    -webkit-transition: all 0.275s;
    transition: all 0.275s;
  }

  .mc4wp-form-fields .main-form [type="checkbox"]:not(:checked)+span:after,
  .mc4wp-form-fields .main-form [type="checkbox"]:checked+span:after {
    content: "\f118";
    font-family: Flaticon;
    position: absolute;
    top: 15px;
    left: 5.5px;
    font-size: 11px;
    font-weight: 900;
    color: #ff6b52;
    line-height: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .mc4wp-form-fields .main-form [type="checkbox"]:not(:checked)+span:after {
    opacity: 0;
  }

  .mc4wp-form-fields .main-form [type="checkbox"]:checked+span:after {
    opacity: 1;
  }

  @media (max-width: 479px) {
    .mc4wp-form-fields .main-form input[type="email"] {
      height: 48px;
      padding: 0 170px 0 30px;
    }

    .mc4wp-form-fields .main-form input[type="submit"] {
      height: 48px;
      width: 135px;
      font-size: 14px;
    }
  }

  #back-to-top {
    background: #ff6b52;
    color: #fff;
    border: 0 none;
    cursor: pointer;
    width: 42px;
    height: 45px;
    line-height: 45px;
    opacity: 0;
    visibility: hidden;
    outline: medium none;
    position: fixed;
    right: 40px;
    bottom: -20px;
    text-align: center;
    text-decoration: none;
    transition: opacity 0.2s ease-out 0s;
    z-index: 1000;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
  }

  #back-to-top i:before {
    font-size: 17px;
  }
}

@media all {
  .elementor-screen-only {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .elementor *,
  .elementor :after,
  .elementor :before {
    box-sizing: border-box;
  }

  .elementor a {
    box-shadow: none;
    text-decoration: none;
  }

  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .elementor .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure {
    margin: 0;
  }

  .elementor .elementor-background-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  .elementor-widget-wrap .elementor-element.elementor-widget__width-auto,
  .elementor-widget-wrap .elementor-element.elementor-widget__width-initial {
    max-width: 100%;
  }

  .elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
  }

  .elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap);
  }

  .elementor-align-center {
    text-align: center;
  }

  .elementor-align-center .elementor-button {
    width: auto;
  }

  .elementor-align-right {
    text-align: right;
  }

  .elementor-align-right .elementor-button {
    width: auto;
  }

  .elementor-align-left {
    text-align: left;
  }

  .elementor-align-left .elementor-button {
    width: auto;
  }

  @media (max-width: 767px) {
    .elementor-mobile-align-center {
      text-align: center;
    }

    .elementor-mobile-align-center .elementor-button {
      width: auto;
    }

    .elementor-mobile-align-left {
      text-align: left;
    }
  }

  .elementor-section {
    position: relative;
  }

  .elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  @media (max-width: 1024px) {
    .elementor-section .elementor-container {
      flex-wrap: wrap;
    }
  }

  .elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
  }

  .elementor-section.elementor-section-stretched {
    position: relative;
    width: 100%;
  }

  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex;
  }

  .elementor-widget-wrap>.elementor-element {
    width: 100%;
  }

  .elementor-widget {
    position: relative;
  }

  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }

  .elementor-column {
    position: relative;
    min-height: 1px;
    display: flex;
  }

  .elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 10px;
  }

  .elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
    padding: 15px;
  }

  @media (min-width: 768px) {
    .elementor-column.elementor-col-20 {
      width: 20%;
    }

    .elementor-column.elementor-col-25 {
      width: 25%;
    }

    .elementor-column.elementor-col-33 {
      width: 33.333%;
    }

    .elementor-column.elementor-col-50 {
      width: 50%;
    }

    .elementor-column.elementor-col-100 {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .elementor-column {
      width: 100%;
    }
  }

  ul.elementor-icon-list-items.elementor-inline-items {
    display: flex;
    flex-wrap: wrap;
  }

  ul.elementor-icon-list-items.elementor-inline-items .elementor-inline-item {
    word-break: break-word;
  }

  .elementor-grid {
    display: grid;
    grid-column-gap: var(--grid-column-gap);
    grid-row-gap: var(--grid-row-gap);
  }

  .elementor-grid .elementor-grid-item {
    min-width: 0;
  }

  .elementor-grid-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
  }

  .elementor-grid-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  @media (min-width: 1025px) {
    #elementor-device-mode:after {
      content: "desktop";
    }
  }

  /* @media (min-width: -1) {
    #elementor-device-mode:after {
      content: "widescreen";
    }
  }
  @media (max-width: -1) {
    #elementor-device-mode:after {
      content: "laptop";
      content: "tablet_extra";
    }
  } */
  @media (max-width: 1024px) {
    #elementor-device-mode:after {
      content: "tablet";
    }
  }

  /* @media (max-width: -1) {
    #elementor-device-mode:after {
      content: "mobile_extra";
    }
  } */
  @media (max-width: 767px) {
    #elementor-device-mode:after {
      content: "mobile";
    }
  }

  .elementor-element .elementor-widget-container {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s, transform var(--e-transform-transition-duration, 0.4s);
  }

  .elementor-button {
    display: inline-block;
    line-height: 1;
    background-color: #69727d;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all 0.3s;
  }

  .elementor-button:focus,
  .elementor-button:hover,
  .elementor-button:visited {
    color: #fff;
  }

  .elementor-button-content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
  }

  .elementor-button-text {
    display: inline-block;
  }

  .elementor-button span {
    text-decoration: inherit;
  }

  .elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all 0.3s;
    color: #69727d;
    font-size: 50px;
    text-align: center;
  }

  .elementor-icon:hover {
    color: #69727d;
  }

  .elementor-icon i {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
  }

  .elementor-icon i:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .elementor-element {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
  }

  .elementor .elementor-element ul.elementor-icon-list-items {
    padding: 0;
  }

  .elementor-widget-divider {
    --divider-border-style: none;
    --divider-border-width: 1px;
    --divider-color: #0c0d0e;
    --divider-icon-size: 20px;
    --divider-element-spacing: 10px;
    --divider-pattern-height: 24px;
    --divider-pattern-size: 20px;
    --divider-pattern-url: none;
    --divider-pattern-repeat: repeat-x;
  }

  .elementor-widget-divider .elementor-divider {
    display: flex;
  }

  .elementor-widget-divider .elementor-divider-separator {
    display: flex;
    margin: 0;
    direction: ltr;
  }

  .elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
    border-block-start: var(--divider-border-width) var(--divider-border-style) var(--divider-color);
  }

  .elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1;
  }

  .elementor-widget.elementor-icon-list--layout-inline .elementor-widget-container {
    overflow: hidden;
  }

  .elementor-widget .elementor-icon-list-items.elementor-inline-items {
    margin-right: -8px;
    margin-left: -8px;
  }

  .elementor-widget .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right: 8px;
    margin-left: 8px;
  }

  .elementor-widget .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    width: auto;
    left: auto;
    right: auto;
    position: relative;
    height: 100%;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-left-width: 1px;
    border-style: solid;
    right: -8px;
  }

  .elementor-widget .elementor-icon-list-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .elementor-widget .elementor-icon-list-item {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .elementor-widget .elementor-icon-list-item:after {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .elementor-widget .elementor-icon-list-item,
  .elementor-widget .elementor-icon-list-item a {
    display: flex;
    font-size: inherit;
    align-items: var(--icon-vertical-align, center);
  }

  .elementor-widget.elementor-list-item-link-full_width a {
    width: 100%;
  }

  .elementor-widget.elementor-align-right .elementor-icon-list-item,
  .elementor-widget.elementor-align-right .elementor-icon-list-item a {
    justify-content: flex-end;
    text-align: right;
  }

  .elementor-widget.elementor-align-right .elementor-icon-list-items {
    justify-content: flex-end;
  }

  .elementor-widget:not(.elementor-align-right) .elementor-icon-list-item:after {
    left: 0;
  }

  .elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after {
    right: 0;
  }

  /*@media (min-width: -1) {
    .elementor-widget:not(.elementor-widescreen-align-right)
      .elementor-icon-list-item:after {
      left: 0;
    }
    .elementor-widget:not(.elementor-widescreen-align-left)
      .elementor-icon-list-item:after {
      right: 0;
    }
  }
   @media (max-width: -1) {
    .elementor-widget:not(.elementor-laptop-align-right)
      .elementor-icon-list-item:after {
      left: 0;
    }
    .elementor-widget:not(.elementor-laptop-align-left)
      .elementor-icon-list-item:after {
      right: 0;
    }
  }
  @media (max-width: -1) {
    .elementor-widget:not(.elementor-tablet_extra-align-right)
      .elementor-icon-list-item:after {
      left: 0;
    }
    .elementor-widget:not(.elementor-tablet_extra-align-left)
      .elementor-icon-list-item:after {
      right: 0;
    }
  } */
  @media (max-width: 1024px) {
    .elementor-widget:not(.elementor-tablet-align-right) .elementor-icon-list-item:after {
      left: 0;
    }

    .elementor-widget:not(.elementor-tablet-align-left) .elementor-icon-list-item:after {
      right: 0;
    }
  }

  /* @media (max-width: -1) {
    .elementor-widget:not(.elementor-mobile_extra-align-right)
      .elementor-icon-list-item:after {
      left: 0;
    }
    .elementor-widget:not(.elementor-mobile_extra-align-left)
      .elementor-icon-list-item:after {
      right: 0;
    }
  } */
  @media (max-width: 767px) {

    .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item,
    .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item a {
      justify-content: flex-start;
      text-align: left;
    }

    .elementor-widget.elementor-mobile-align-left .elementor-inline-items {
      justify-content: flex-start;
    }

    .elementor-widget:not(.elementor-mobile-align-right) .elementor-icon-list-item:after {
      left: 0;
    }

    .elementor-widget:not(.elementor-mobile-align-left) .elementor-icon-list-item:after {
      right: 0;
    }
  }

  .elementor-widget-image {
    text-align: center;
  }

  .elementor-widget-image img {
    vertical-align: middle;
    display: inline-block;
  }

  .elementor-widget-social-icons.elementor-grid-0 .elementor-widget-container {
    line-height: 1;
    font-size: 0;
  }

  .elementor-widget-social-icons .elementor-grid {
    grid-column-gap: var(--grid-column-gap, 5px);
    grid-row-gap: var(--grid-row-gap, 5px);
    grid-template-columns: var(--grid-template-columns);
    justify-content: var(--justify-content, center);
    justify-items: var(--justify-content, center);
  }

  .elementor-icon.elementor-social-icon {
    font-size: var(--icon-size, 25px);
    line-height: var(--icon-size, 25px);
    width: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
    height: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
  }

  .elementor-social-icon {
    --e-social-icon-icon-color: #fff;
    display: inline-flex;
    background-color: #69727d;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }

  .elementor-social-icon i {
    color: var(--e-social-icon-icon-color);
  }

  .elementor-social-icon:last-child {
    margin: 0;
  }

  .elementor-social-icon:hover {
    opacity: 0.9;
    color: #fff;
  }

  .elementor-social-icon-facebook-f {
    background-color: #3b5998;
  }

  .elementor-social-icon-instagram {
    background-color: #262626;
  }

  .elementor-social-icon-linkedin-in {
    background-color: #0077b5;
  }

  .elementor-shape-rounded .elementor-icon.elementor-social-icon {
    border-radius: 10%;
  }

  .elementor-column .elementor-spacer-inner {
    height: var(--spacer-size);
  }
}

@media all {
  .elementor-kit-7 {
    --e-global-color-primary: #6ec1e4;
    --e-global-color-secondary: #54595f;
    --e-global-color-text: #7a7a7a;
    --e-global-color-accent: #61ce70;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
  }

  .elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
  }

  .elementor-widget:not(:last-child) {
    margin-block-end: 20px;
  }

  .elementor-element {
    --widgets-spacing: 20px 20px;
  }

  @media (max-width: 1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
      max-width: 1024px;
    }
  }

  @media (max-width: 767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
      max-width: 767px;
    }
  }
}

@media all {
  .elementor-widget-heading .elementor-heading-title {
    color: var(--e-global-color-primary);
    font-family: var(--e-global-typography-primary-font-family), Sans-serif;
    font-weight: var(--e-global-typography-primary-font-weight);
  }

  .elementor-widget-text-editor {
    color: var(--e-global-color-text);
    font-family: var(--e-global-typography-text-font-family), Sans-serif;
    font-weight: var(--e-global-typography-text-font-weight);
  }

  .elementor-widget-button .elementor-button {
    font-family: var(--e-global-typography-accent-font-family), Sans-serif;
    font-weight: var(--e-global-typography-accent-font-weight);
    background-color: var(--e-global-color-accent);
  }

  .elementor-widget-divider {
    --divider-color: var(--e-global-color-secondary);
  }

  .elementor-widget-icon-list .elementor-icon-list-item:not(:last-child):after {
    border-color: var(--e-global-color-text);
  }

  .elementor-widget-icon-list .elementor-icon-list-item>a {
    font-family: var(--e-global-typography-text-font-family), Sans-serif;
    font-weight: var(--e-global-typography-text-font-weight);
  }

  .elementor-widget-icon-list .elementor-icon-list-text {
    color: var(--e-global-color-secondary);
  }
}



@media all {
  /* .elementor-4516 .elementor-element.elementor-element-f72d2fb:not(.elementor-motion-effects-element-type-background) {
    background-color: transparent;
    background-image: linear-gradient(180deg, #DAE0FF 50%, #ffffff 120%);
  } */

  .elementor-4516 .elementor-element.elementor-element-f72d2fb>.elementor-background-overlay {
    /* background-image: url("/public/Assets/home14-bg-1.webp"); */
    background-position: 38% 55%;
    background-repeat: no-repeat;
    opacity: 1;
    transition: background 0.3s, border-radius 10s, opacity 0.3s;
  }

  .elementor-4516 .elementor-element.elementor-element-f72d2fb,
  .elementor-4516 .elementor-element.elementor-element-f72d2fb>.elementor-background-overlay {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-f72d2fb {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 187px 0px 120px 0px;
  }

  .add-margin {
    margin: 20px 20px 20px 20px;
  }

  .elementor-4516 .elementor-element.elementor-element-e107369>.elementor-element-populated {
    padding:5px 15px 15px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-bcad938 .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 100px;
  }

  .elementor-4516 .elementor-element.elementor-element-bcad938>.elementor-widget-container {
    padding: 45px 0px 40px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-5ba24b6 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4516 .elementor-element.elementor-element-5ba24b6>.elementor-widget-container {
    padding: 20px 0px 5px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-a3ce76b>.elementor-widget-container {
    margin: 0px 0px 30px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-d004c9e.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-d004c9e .ot-icon-box__icon {
    font-size: 24px;
  }

  .elementor-4516 .elementor-element.elementor-element-d004c9e .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-d004c9e .icon-box-title {
    margin-bottom: 0px;
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-fd2d84c>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-546fb06>.elementor-widget-container {
    margin: 0px -211px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-ba05fc7 {
    padding: 150px 0px 150px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-22c4b56>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-379d728 {
    text-align: center;
  }

  .elementor-4516 .elementor-element.elementor-element-379d728 .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-weight: 600;
  }

  .elementor-4516 .elementor-element.elementor-element-379d728>.elementor-widget-container {
    padding: 0px 15px 60px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-2b0971a .owl-stage {
    display: flex;
    align-items: center;
  }

  .elementor-4516 .elementor-element.elementor-element-2b0971a .ot-client-logo-slider span {
    padding: 26px 23px 26px 28px;
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-2b0971a .ot-client-logo-slider .owl-item svg {
    fill: #696871;
  }

  .elementor-4516 .elementor-element.elementor-element-2b0971a .client_link .client-hover-logo svg {
    fill: #6727e8;
  }

  .elementor-4516 .elementor-element.elementor-element-2b0971a .ot-client-logo-slider .client-hover-logo {
    background: #f8f4ff;
  }

  .elementor-4516 .elementor-element.elementor-element-2b0971a>.elementor-widget-container {
    margin: 0px 0px 12px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-73d223b .owl-stage {
    display: flex;
    align-items: center;
  }

  .elementor-4516 .elementor-element.elementor-element-73d223b .ot-client-logo-slider span {
    padding: 26px 23px 26px 28px;
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-73d223b .ot-client-logo-slider .owl-item svg {
    fill: #696871;
  }

  .elementor-4516 .elementor-element.elementor-element-73d223b .client_link .client-hover-logo svg {
    fill: #6727e8;
  }

  .elementor-4516 .elementor-element.elementor-element-73d223b .ot-client-logo-slider .client-hover-logo {
    background: #f8f4ff;
  }

  .elementor-4516 .elementor-element.elementor-element-43c04fb:not(.elementor-motion-effects-element-type-background) {
    background-color: transparent;
    background-image: linear-gradient(0deg, #d4dbff 0%, #ffffff 115%);
  }

  .elementor-4516 .elementor-element.elementor-element-43c04fb>.elementor-background-overlay {
    background-image: url("/public/Assets/home14-bg-2.webp");
    background-position: 67% 93%;
    background-repeat: no-repeat;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }

  .elementor-4516 .elementor-element.elementor-element-43c04fb,
  .elementor-4516 .elementor-element.elementor-element-43c04fb>.elementor-background-overlay {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-43c04fb {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 50px 0px 50px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-eefbc5d>.elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-60f5730 {
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-4b45ea9>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-01927aa .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
  }

  .elementor-4516 .elementor-element.elementor-element-01927aa>.elementor-widget-container {
    padding: 0px 0px 50px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-184d506>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-e726e68 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4516 .elementor-element.elementor-element-e726e68>.elementor-widget-container {
    padding: 0px 0px 50px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-6194b98>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-a60d6a2 .ot-icon-box__icon {
    font-size: 36px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-a60d6a2 .ot-icon-box__icon svg {
    fill: #4316c4;
  }

  .elementor-4516 .elementor-element.elementor-element-a60d6a2 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-a60d6a2 .ot-icon-box .icon-box-title a:hover {
    color: #5231c8;
  }

  .elementor-4516 .elementor-element.elementor-element-a60d6a2 .icon-box-title {
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-a60d6a2 .icon-box-divider span {
    background: #ffffff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-a60d6a2 .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-a60d6a2>.elementor-widget-container {
    margin: 0px 0px 55px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-6b2ecf8 .elementor-button {
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    fill: #0f1a51;
    color: #0f1a51;
    background-color: #ffffff40;
    border-radius: 25px 25px 25px 25px;
  }

  .elementor-4516 .elementor-element.elementor-element-6b2ecf8 .elementor-button:hover,
  .elementor-4516 .elementor-element.elementor-element-6b2ecf8 .elementor-button:focus {
    color: #4316c4;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-100470c>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-0a2f99f .ot-icon-box__icon {
    font-size: 36px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-0a2f99f .ot-icon-box__icon svg {
    fill: #4316c4;
  }

  .elementor-4516 .elementor-element.elementor-element-0a2f99f .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-0a2f99f .ot-icon-box .icon-box-title a:hover {
    color: #5231c8;
  }

  .elementor-4516 .elementor-element.elementor-element-0a2f99f .icon-box-title {
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-0a2f99f .icon-box-divider span {
    background: #ffffff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-0a2f99f .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-0a2f99f>.elementor-widget-container {
    margin: 0px 0px 25px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-c4db568 .elementor-button {
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    fill: #0f1a51;
    color: #0f1a51;
    background-color: #ffffff40;
    border-radius: 25px 25px 25px 25px;
  }

  .elementor-4516 .elementor-element.elementor-element-c4db568 .elementor-button:hover,
  .elementor-4516 .elementor-element.elementor-element-c4db568 .elementor-button:focus {
    color: #4316c4;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-4a991bb>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-2264eb5 .ot-icon-box__icon {
    font-size: 36px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-2264eb5 .ot-icon-box__icon svg {
    fill: #4316c4;
  }

  .elementor-4516 .elementor-element.elementor-element-2264eb5 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-2264eb5 .ot-icon-box .icon-box-title a:hover {
    color: #5231c8;
  }

  .elementor-4516 .elementor-element.elementor-element-2264eb5 .icon-box-title {
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-2264eb5 .icon-box-divider span {
    background: #ffffff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-2264eb5 .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
  }

  .elementor-4516 .elementor-element.elementor-element-2264eb5>.elementor-widget-container {
    margin: 0px 0px 25px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-5078c70 .elementor-button {
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    fill: #0f1a51;
    color: #0f1a51;
    background-color: #ffffff40;
    border-radius: 25px 25px 25px 25px;
  }

  .elementor-4516 .elementor-element.elementor-element-5078c70 .elementor-button:hover,
  .elementor-4516 .elementor-element.elementor-element-5078c70 .elementor-button:focus {
    color: #4316c4;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-08d2952:not(.elementor-motion-effects-element-type-background) {
    background-image: url("/public/Assets/home14-bg-3.webp");
    background-position: 22% 67%;
    background-repeat: no-repeat;
  }

  .elementor-4516 .elementor-element.elementor-element-08d2952 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 150px 0px 150px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-c8f90a0.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }

  .elementor-4516 .elementor-element.elementor-element-c8f90a0>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-65e0774>.elementor-element-populated {
    margin: 0px 0px 0px 100px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 100px;
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-50e811f .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
  }

  .elementor-4516 .elementor-element.elementor-element-50e811f>.elementor-widget-container {
    padding: 0px 0px 20px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-f308e23 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4516 .elementor-element.elementor-element-910a886 {
    --divider-border-style: solid;
    --divider-color: #d8dfff;
    --divider-border-width: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-910a886 .elementor-divider-separator {
    width: 100%;
  }

  .elementor-4516 .elementor-element.elementor-element-910a886 .elementor-divider {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .elementor-4516 .elementor-element.elementor-element-f096fd1.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-f096fd1 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: transparent;
    background-image: linear-gradient(0deg, #d4dbff 0%, #ffffff 100%);
  }

  .elementor-4516 .elementor-element.elementor-element-f096fd1 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-f096fd1 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-f096fd1 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-af3e277.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-af3e277 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: transparent;
    background-image: linear-gradient(0deg, #d4dbff 0%, #ffffff 100%);
  }

  .elementor-4516 .elementor-element.elementor-element-af3e277 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-af3e277 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-af3e277 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-03daf2f.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-03daf2f .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: transparent;
    background-image: linear-gradient(0deg, #d4dbff 0%, #ffffff 100%);
  }

  .elementor-4516 .elementor-element.elementor-element-03daf2f .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-03daf2f .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-03daf2f .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-1888b92.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-1888b92 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: transparent;
    background-image: linear-gradient(0deg, #d4dbff 0%, #ffffff 100%);
  }

  .elementor-4516 .elementor-element.elementor-element-1888b92 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-1888b92 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-1888b92 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-dabf843.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-dabf843 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: transparent;
    background-image: linear-gradient(0deg, #d4dbff 0%, #ffffff 100%);
  }

  .elementor-4516 .elementor-element.elementor-element-dabf843 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-dabf843 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-dabf843 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-f2cf1a8:not(.elementor-motion-effects-element-type-background) {
    background-color: transparent;
    background-image: linear-gradient(0deg, #d4dbff 0%, #ffffff 115%);
  }

  .elementor-4516 .elementor-element.elementor-element-f2cf1a8>.elementor-background-overlay {
    background-image: url("/public/Assets/home14-bg-4.webp");
    background-position: 68% 76%;
    background-repeat: no-repeat;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }

  .elementor-4516 .elementor-element.elementor-element-f2cf1a8,
  .elementor-4516 .elementor-element.elementor-element-f2cf1a8>.elementor-background-overlay {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-f2cf1a8 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 50px 0px 50px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-4402f26>.elementor-element-populated {
    border-radius: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-4402f26>.elementor-element-populated {
    margin: 0px 90px 0px 0px;
    --e-column-margin-right: 90px;
    --e-column-margin-left: 0px;
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-e820547 .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 38px;
    font-weight: bold;
    line-height: 50px;
  }

  .elementor-4516 .elementor-element.elementor-element-e820547>.elementor-widget-container {
    padding: 20px 0px 20px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-d68c172 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-weight: 500;
    font-size: 18.6px;
    /* font-weight: bold; */
    
  }

  .elementor-4516 .elementor-element.elementor-element-5acda1c>.elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-72b276b>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-a8810cf .ot-counter span {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 54px;
  }

  .elementor-4516 .elementor-element.elementor-element-a8810cf .ot-counter h6 {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }

  .elementor-4516 .elementor-element.elementor-element-1a80c21 {
    --spacer-size: 50px;
  }

  .elementor-4516 .elementor-element.elementor-element-02856d8 .ot-counter span {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 54px;
  }

  .elementor-4516 .elementor-element.elementor-element-02856d8 .ot-counter h6 {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }

  .elementor-4516 .elementor-element.elementor-element-a40b797>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-a4f9765 .ot-counter span {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 54px;
  }

  .elementor-4516 .elementor-element.elementor-element-a4f9765 .ot-counter h6 {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }

  .elementor-4516 .elementor-element.elementor-element-8da7eac {
    --spacer-size: 50px;
  }

  .elementor-4516 .elementor-element.elementor-element-fb3d078 .ot-counter span {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 54px;
  }

  .elementor-4516 .elementor-element.elementor-element-fb3d078 .ot-counter h6 {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }

  .elementor-4516 .elementor-element.elementor-element-427149c:not(.elementor-motion-effects-element-type-background) {
    background-color: #f6f7fc;
  }

  .elementor-4516 .elementor-element.elementor-element-427149c {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-427149c {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 60px 0px 60px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-c6c4b78>.elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-df293e0:not(.elementor-motion-effects-element-type-background) {
    background-image: url("/public/Assets/home14-bg-5.webp");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .elementor-4516 .elementor-element.elementor-element-df293e0 {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-df293e0 {
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999936, 0.9999999999999967, 84.00000000000003, 0.06);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: -110px;
    margin-bottom: 0px;
    padding: 50px 80px 50px 80px;
  }

  .elementor-4516 .elementor-element.elementor-element-9c007dc>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-d85f22e .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 50px;
  }

  .elementor-4516 .elementor-element.elementor-element-3195a2e.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }

  .elementor-4516 .elementor-element.elementor-element-3195a2e>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-a57f984 .elementor-button {
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    background-color: #4316c4;
    border-radius: 30px 30px 30px 30px;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999936, 0.9999999999999967, 84.00000000000003, 0.08);
    padding: 22px 80px 22px 80px;
  }

  .elementor-4516 .elementor-element.elementor-element-a57f984 .elementor-button:hover,
  .elementor-4516 .elementor-element.elementor-element-a57f984 .elementor-button:focus {
    background-color: #0f1a51;
  }

  .elementor-4516 .elementor-element.elementor-element-c17d185 {
    --spacer-size: 50px;
  }

  .elementor-4516 .elementor-element.elementor-element-158c27d>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
    /* background-color: blue; */
  }

  .elementor-4516 .elementor-element.elementor-element-b0d750c {
    text-align: center;
  }

  .elementor-4516 .elementor-element.elementor-element-b0d750c .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    /* font-size: 48px; */
    font-weight: bold;
    line-height: 60px;
    /* background-color: red; */
  }

  .elementor-4516 .elementor-element.elementor-element-b0d750c>.elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-e8bb90c>.elementor-widget-container {
    padding: 25px 0px 0px 0px;
    
  }

  .elementor-4516 .elementor-element.elementor-element-5d89801>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa.ot-position-left .ot-icon-box__icon {
    margin-right: 30px;
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa .ot-icon-box__icon {
    font-size: 36px;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 2px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa .ot-icon-box__icon svg {
    fill: white;
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa .ot-icon-box:hover .ot-icon-box__icon svg {
    fill: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa .ot-icon-box:hover .ot-icon-box__icon {
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa .ot-icon-box {
    padding: 50px 65px 40px 40px;
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa .icon-box-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa .icon-box-divider span {
    background: #d8dfff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa:hover .elementor-widget-container {
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.04);
  }

  .elementor-4516 .elementor-element.elementor-element-841b4aa>.elementor-widget-container {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-9d55318>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19.ot-position-left .ot-icon-box__icon {
    margin-right: 30px;
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19 .ot-icon-box__icon {
    font-size: 36px;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 2px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19 .ot-icon-box__icon svg {
    fill: white;
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19 .ot-icon-box:hover .ot-icon-box__icon svg {
    fill: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19 .ot-icon-box:hover .ot-icon-box__icon {
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19 .ot-icon-box {
    padding: 50px 65px 40px 40px;
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19 .icon-box-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19 .icon-box-divider span {
    background: #d8dfff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19 .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19:hover .elementor-widget-container {
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.04);
  }

  .elementor-4516 .elementor-element.elementor-element-86cad19>.elementor-widget-container {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-2531999>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3.ot-position-left .ot-icon-box__icon {
    margin-right: 30px;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3 .ot-icon-box__icon {
    font-size: 36px;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 2px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3 .ot-icon-box__icon svg {
    fill: white;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3 .ot-icon-box:hover .ot-icon-box__icon svg {
    fill: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3 .ot-icon-box:hover .ot-icon-box__icon {
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3 .ot-icon-box {
    padding: 50px 65px 40px 40px;
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3 .icon-box-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3 .icon-box-divider span {
    background: #d8dfff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3 .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3>.elementor-widget-container {
    padding: 30px 0px 0px 0px;
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-093bfd3:hover .elementor-widget-container {
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.04);
  }

  .elementor-4516 .elementor-element.elementor-element-0f5bc19>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b.ot-position-left .ot-icon-box__icon {
    margin-right: 30px;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b .ot-icon-box__icon {
    font-size: 36px;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 2px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-303202b .ot-icon-box__icon svg {
    fill: white;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b .ot-icon-box:hover .ot-icon-box__icon svg {
    fill: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b .ot-icon-box:hover .ot-icon-box__icon {
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }

  .elementor-4516 .elementor-element.elementor-element-303202b .ot-icon-box {
    padding: 50px 65px 40px 40px;
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b .icon-box-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b .icon-box-divider span {
    background: #d8dfff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b>.elementor-widget-container {
    padding: 30px 0px 0px 0px;
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-303202b:hover .elementor-widget-container {
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.04);
  }

  .elementor-4516 .elementor-element.elementor-element-ec09f5b>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7.ot-position-left .ot-icon-box__icon {
    margin-right: 30px;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7 .ot-icon-box__icon {
    font-size: 36px;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 2px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7 .ot-icon-box__icon svg {
    fill: white;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7 .ot-icon-box:hover .ot-icon-box__icon svg {
    fill: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7 .ot-icon-box:hover .ot-icon-box__icon {
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7 .ot-icon-box {
    padding: 50px 65px 40px 40px;
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7 .icon-box-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7 .icon-box-divider span {
    background: #d8dfff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7 .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7>.elementor-widget-container {
    padding: 30px 0px 0px 0px;
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-10b7cf7:hover .elementor-widget-container {
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.04);
  }

  .elementor-4516 .elementor-element.elementor-element-71b2524>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9.ot-position-left .ot-icon-box__icon {
    margin-right: 30px;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9 .ot-icon-box__icon {
    font-size: 36px;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 2px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.08);
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9 .ot-icon-box__icon svg {
    fill: white;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9 .ot-icon-box:hover .ot-icon-box__icon svg {
    fill: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9 .ot-icon-box:hover .ot-icon-box__icon {
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9 .ot-icon-box {
    padding: 50px 65px 40px 40px;
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9 .icon-box-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9 .icon-box-divider span {
    background: #d8dfff;
    height: 1px;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9 .icon-box-des {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9>.elementor-widget-container {
    padding: 30px 0px 0px 0px;
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-cb429a9:hover .elementor-widget-container {
    background-color: #ffffff;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999922, 0.9999999999999962, 83.99999999999999, 0.04);
  }

  .elementor-4516 .elementor-element.elementor-element-594a496>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-ad5e807 .elementor-button {
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    background-color: #0f1a51;
    border-radius: 30px 30px 30px 30px;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999936, 0.9999999999999967, 84.00000000000003, 0.08);
    padding: 22px 54px 22px 54px;
  }

  .elementor-4516 .elementor-element.elementor-element-ad5e807 .elementor-button:hover,
  .elementor-4516 .elementor-element.elementor-element-ad5e807 .elementor-button:focus {
    background-color: #4316c4;
  }

  .elementor-4516 .elementor-element.elementor-element-ad5e807>.elementor-widget-container {
    margin: 75px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-e750b4a {
    padding: 70px 0px 70px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-ba4ddc0.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }

  .elementor-4516 .elementor-element.elementor-element-ba4ddc0>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-2cd938e>.elementor-element-populated {
    margin: 0px 0px 0px 75px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 100px;
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-d9a3e0d .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
  }

  .elementor-4516 .elementor-element.elementor-element-d9a3e0d>.elementor-widget-container {
    margin: 20px 0px 0px 0px;
    padding: 0px 0px 25px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-8f7dbf6 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4516 .elementor-element.elementor-element-8f7dbf6>.elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-194d079 {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    line-height: 30px;
  }

  .elementor-4516 .elementor-element.elementor-element-456a4f5:not(.elementor-motion-effects-element-type-background) {
    background-color: transparent;
    background-image: linear-gradient(0deg, #d4dbff 0%, #ffffff 115%);
  }

  .elementor-4516 .elementor-element.elementor-element-456a4f5>.elementor-background-overlay {
    background-image: url("/public/Assets/home14-bg-6.webp");
    background-position: 64% 51%;
    background-repeat: no-repeat;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }

  .elementor-4516 .elementor-element.elementor-element-456a4f5,
  .elementor-4516 .elementor-element.elementor-element-456a4f5>.elementor-background-overlay {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-456a4f5 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 150px 0px 150px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-b929864>.elementor-element-populated {
    border-radius: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-b929864>.elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-f7d7dd9 .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
  }

  .elementor-4516 .elementor-element.elementor-element-f7d7dd9>.elementor-widget-container {
    padding: 0px 0px 20px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-c3d325c {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4516 .elementor-element.elementor-element-c3d325c>.elementor-widget-container {
    padding: 0px 0px 30px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-5f98f48 .elementor-button {
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    background-color: #4316c4;
    border-radius: 30px 30px 30px 30px;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999936, 0.9999999999999967, 84.00000000000003, 0.08);
    padding: 22px 80px 22px 80px;
  }

  .elementor-4516 .elementor-element.elementor-element-5f98f48 .elementor-button:hover,
  .elementor-4516 .elementor-element.elementor-element-5f98f48 .elementor-button:focus {
    background-color: #0f1a51;
  }

  .elementor-4516 .elementor-element.elementor-element-f9cfb15>.elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-67e1761>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-96fb4a4.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-96fb4a4 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-96fb4a4 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-96fb4a4 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-96fb4a4 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-e9d58cf.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-e9d58cf .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-e9d58cf .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-e9d58cf .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-e9d58cf .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-1311674.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-1311674 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-1311674 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-1311674 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-1311674 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-92590fb.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-92590fb .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-92590fb .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-92590fb .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-92590fb .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-29fe131.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-29fe131 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-29fe131 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-29fe131 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-29fe131 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-e4a0690.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-e4a0690 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-e4a0690 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-e4a0690 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-e4a0690 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-2af1818.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-2af1818 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-2af1818 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-2af1818 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-2af1818 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-5c80eb5.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-5c80eb5 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-5c80eb5 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-5c80eb5 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-5c80eb5 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-53a851b.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-53a851b .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-53a851b .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-53a851b .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-53a851b .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-53a851b>.elementor-widget-container {
    margin: 0px -3px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-f7af9b4>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-69ff6e6.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-69ff6e6 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-69ff6e6 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-69ff6e6 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-69ff6e6 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-0179afb.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-0179afb .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-0179afb .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-0179afb .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-0179afb .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-1c56b38.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-1c56b38 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-1c56b38 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-1c56b38 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-1c56b38 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-a97436f.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-a97436f .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-a97436f .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-a97436f .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-a97436f .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-5821d4e.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-5821d4e .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-5821d4e .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-5821d4e .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-5821d4e .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-750f328.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-750f328 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-750f328 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-750f328 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-750f328 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-7cdfe24.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-7cdfe24 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-7cdfe24 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-7cdfe24 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-7cdfe24 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-d2601ed.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-d2601ed .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-d2601ed .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-d2601ed .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-d2601ed .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-6232017.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-6232017 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-6232017 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-6232017 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-6232017 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-6232017>.elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-50e2f00>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-fc48dbd.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-fc48dbd .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-fc48dbd .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-fc48dbd .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-fc48dbd .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-70bceb3.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-70bceb3 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-70bceb3 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-70bceb3 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-70bceb3 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-f7af63c.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-f7af63c .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-f7af63c .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-f7af63c .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-f7af63c .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-de1df67.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-de1df67 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-de1df67 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-de1df67 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-de1df67 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-dc57470.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-dc57470 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-dc57470 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-dc57470 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-dc57470 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-dc986e4.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-dc986e4 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-dc986e4 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-dc986e4 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-dc986e4 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-a245cd7.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-a245cd7 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-a245cd7 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-a245cd7 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-a245cd7 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-18e1c4b:not(.elementor-motion-effects-element-type-background) {
    background-color: #f6f7fc;
  }

  .elementor-4516 .elementor-element.elementor-element-18e1c4b {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-18e1c4b {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 150px 0px 150px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-6d2a439>.elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-7b9c4c4>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-7cff8c9 {
    text-align: center;
  }

  .elementor-4516 .elementor-element.elementor-element-7cff8c9 .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
  }

  .elementor-4516 .elementor-element.elementor-element-7cff8c9>.elementor-widget-container {
    padding: 0px 0px 20px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-f2999ad {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4516 .elementor-element.elementor-element-f2999ad>.elementor-widget-container {
    padding: 0px 0px 50px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-49f1128>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-c5a49b3 .elementor-heading-title {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-c5a49b3>.elementor-widget-container {
    padding: 0px 0px 20px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-b90d4d5 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-4183e9e {
    --spacer-size: 40px;
  }

  .elementor-4516 .elementor-element.elementor-element-0318db1 .elementor-heading-title {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-0318db1>.elementor-widget-container {
    padding: 0px 0px 20px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-eac1a01 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-7e2d383>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-cffdf10 .elementor-heading-title {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-cffdf10>.elementor-widget-container {
    padding: 0px 0px 20px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-f515757 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-f46db72 {
    --spacer-size: 40px;
  }

  .elementor-4516 .elementor-element.elementor-element-b5dd75c .elementor-heading-title {
    color: #4316c4;
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .elementor-4516 .elementor-element.elementor-element-b5dd75c>.elementor-widget-container {
    padding: 0px 0px 20px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-ec243a6 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-1c4a8b2 .elementor-button {
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    background-color: #0f1a51;
    border-radius: 30px 30px 30px 30px;
    box-shadow: 32px 32px 29px 0px rgba(30.999999999999936, 0.9999999999999967, 84.00000000000003, 0.08);
    padding: 22px 40px 22px 40px;
  }

  .elementor-4516 .elementor-element.elementor-element-1c4a8b2 .elementor-button:hover,
  .elementor-4516 .elementor-element.elementor-element-1c4a8b2 .elementor-button:focus {
    background-color: #4316c4;
  }

  .elementor-4516 .elementor-element.elementor-element-1c4a8b2>.elementor-widget-container {
    margin: 50px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-e12f41f:not(.elementor-motion-effects-element-type-background) {
    background-color: transparent;
    background-image: linear-gradient(180deg, #d4dbff 0%, #ffffff 120%);
  }

  .elementor-4516 .elementor-element.elementor-element-e12f41f>.elementor-background-overlay {
    background-image: url("/public/Assets/home14-bg-7.webp");
    background-position: 47% 13%;
    background-repeat: no-repeat;
    opacity: 0.5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }

  .elementor-4516 .elementor-element.elementor-element-e12f41f,
  .elementor-4516 .elementor-element.elementor-element-e12f41f>.elementor-background-overlay {
    border-radius: 10px 10px 10px 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-e12f41f {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 150px 0px 150px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-2994eaf>.elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-67a5066>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-997b631 {
    text-align: center;
  }

  .elementor-4516 .elementor-element.elementor-element-997b631 .elementor-heading-title {
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
  }

  .elementor-4516 .elementor-element.elementor-element-997b631>.elementor-widget-container {
    margin: 0px 0px 20px 0px;
    padding: 0% 20% 0% 20%;
  }

  .elementor-4516 .elementor-element.elementor-element-0008029 {
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4516 .elementor-element.elementor-element-0008029>.elementor-widget-container {
    padding: 0px 15px 50px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-afce128.elementor-column>.elementor-widget-wrap {
    justify-content: center;
  }

  .elementor-4516 .elementor-element.elementor-element-afce128>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-b3642e4>.elementor-widget-container {
    margin: 0px 0px 30px 0px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-815d7e3.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-815d7e3 .ot-icon-box__icon {
    font-size: 24px;
  }

  .elementor-4516 .elementor-element.elementor-element-815d7e3 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-815d7e3 .icon-box-title {
    margin-bottom: 0px;
    color: #454c6c;
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
  }

  .elementor-4516 .elementor-element.elementor-element-815d7e3 {
    width: auto;
    max-width: auto;
  }

  .elementor-4516 .elementor-element.elementor-element-f44d2f7 {
    padding: 40px 0px 0px 0px;
  }

  .elementor-4516 .elementor-element.elementor-element-5b4851b>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-4dd791e.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-4dd791e .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-4dd791e .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-4dd791e .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-4dd791e .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-f08157a.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-f08157a .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-f08157a .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-f08157a .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-f08157a .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-a904fb3>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-6ce7092.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-6ce7092 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-6ce7092 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-6ce7092 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-6ce7092 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-b988522.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-b988522 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-b988522 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-b988522 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-b988522 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-a85a200>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4516 .elementor-element.elementor-element-740effe.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-740effe .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-740effe .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-740effe .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-740effe .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  .elementor-4516 .elementor-element.elementor-element-b97a4f2.ot-position-left .ot-icon-box__icon {
    margin-right: 10px;
  }

  .elementor-4516 .elementor-element.elementor-element-b97a4f2 .ot-icon-box__icon {
    font-size: 11px;
    padding: 10px;
    background-color: #ffffff;
  }

  .elementor-4516 .elementor-element.elementor-element-b97a4f2 .ot-icon-box__icon svg {
    fill: #3449e3;
  }

  .elementor-4516 .elementor-element.elementor-element-b97a4f2 .ot-icon-box {
    text-align: left;
  }

  .elementor-4516 .elementor-element.elementor-element-b97a4f2 .icon-box-title {
    margin-bottom: 0px;
    color: #0f1a51;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
  }

  @media (max-width: 1024px) {
    .elementor-4516 .elementor-element.elementor-element-f72d2fb>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-546fb06>.elementor-widget-container {
      margin: 0px 0px 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-43c04fb>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-2264eb5 .icon-box-title {
      margin-bottom: 48px;
    }

    .elementor-4516 .elementor-element.elementor-element-08d2952:not(.elementor-motion-effects-element-type-background) {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-65e0774>.elementor-element-populated {
      margin: 60px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-f2cf1a8>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-5acda1c>.elementor-element-populated {
      margin: 60px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-df293e0 {
      padding: 30px 30px 30px 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-a57f984 .elementor-button {
      padding: 18px 58px 18px 58px;
    }

    .elementor-4516 .elementor-element.elementor-element-2cd938e>.elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-456a4f5>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-f9cfb15>.elementor-element-populated {
      margin: 60px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-e12f41f>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-997b631>.elementor-widget-container {
      padding: 0% 10% 0% 10%;
    }
  }

  @media (max-width: 767px) {
    .elementor-4516 .elementor-element.elementor-element-f72d2fb>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-f72d2fb {
      padding: 0px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-bcad938 .elementor-heading-title {
      font-size: 42px;
      line-height: 56px;
    }

    .elementor-4516 .elementor-element.elementor-element-d004c9e .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-546fb06>.elementor-widget-container {
      margin: 0px 0px 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-ba05fc7 {
      padding: 80px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-2b0971a .ot-client-logo-slider span {
      padding: 10px 10px 10px 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-2b0971a>.elementor-widget-container {
      margin: 0px 0px 20px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-73d223b .ot-client-logo-slider span {
      padding: 10px 10px 10px 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-43c04fb>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-43c04fb {
      padding: 50px 0px 50px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-01927aa .elementor-heading-title {
      font-size: 36px;
      line-height: 46px;
    }

    .elementor-4516 .elementor-element.elementor-element-0a2f99f>.elementor-widget-container {
      margin: 40px 0px 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-2264eb5 .icon-box-title {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-2264eb5>.elementor-widget-container {
      padding: 40px 0px 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-08d2952:not(.elementor-motion-effects-element-type-background) {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-08d2952 {
      padding: 80px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-50e811f .elementor-heading-title {
      font-size: 36px;
      line-height: 46px;
    }

    .elementor-4516 .elementor-element.elementor-element-f096fd1 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-f096fd1 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-af3e277 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-af3e277 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-03daf2f .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-03daf2f .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-1888b92 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-1888b92 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-dabf843 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-dabf843 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-f2cf1a8>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-f2cf1a8 {
      padding: 80px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-4402f26>.elementor-element-populated {
      margin: 0px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-e820547 .elementor-heading-title {
      font-size: 32px;
      line-height: 46px;
    }

    .elementor-4516 .elementor-element.elementor-element-02856d8>.elementor-widget-container {
      margin: 0px 0px 50px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-427149c {
      padding: 0px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-df293e0 {
      margin-top: 80px;
      margin-bottom: 0px;
      padding: 40px 10px 40px 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-9c007dc {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-9c007dc>.elementor-element-populated {
      margin: 0px 0px 30px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-d85f22e {
      text-align: center;
    }

    .elementor-4516 .elementor-element.elementor-element-d85f22e .elementor-heading-title {
      font-size: 32px;
    }

    .elementor-4516 .elementor-element.elementor-element-c17d185 {
      --spacer-size: 50px;
    }

    .elementor-4516 .elementor-element.elementor-element-b0d750c .elementor-heading-title {
      font-size: 36px;
      line-height: 46px;
      
    }

    .elementor-4516 .elementor-element.elementor-element-841b4aa .ot-icon-box__icon {
      margin-bottom: 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-841b4aa .ot-icon-box {
      padding: 30px 30px 30px 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-86cad19 .ot-icon-box__icon {
      margin-bottom: 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-86cad19 .ot-icon-box {
      padding: 30px 30px 30px 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-093bfd3 .ot-icon-box__icon {
      margin-bottom: 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-093bfd3 .ot-icon-box {
      padding: 30px 30px 30px 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-093bfd3>.elementor-widget-container {
      margin: 0px 0px 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-303202b .ot-icon-box__icon {
      margin-bottom: 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-303202b .ot-icon-box {
      padding: 30px 30px 30px 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-10b7cf7 .ot-icon-box__icon {
      margin-bottom: 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-10b7cf7 .ot-icon-box {
      padding: 30px 30px 30px 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-cb429a9 .ot-icon-box__icon {
      margin-bottom: 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-cb429a9 .ot-icon-box {
      padding: 30px 30px 30px 30px;
    }

    .elementor-4516 .elementor-element.elementor-element-e750b4a {
      padding: 80px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-d9a3e0d .elementor-heading-title {
      font-size: 36px;
      line-height: 46px;
    }

    .elementor-4516 .elementor-element.elementor-element-456a4f5>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-456a4f5 {
      padding: 80px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-f7d7dd9 .elementor-heading-title {
      font-size: 36px;
      line-height: 46px;
    }

    .elementor-4516 .elementor-element.elementor-element-f9cfb15>.elementor-element-populated {
      margin: 40px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-67e1761 {
      width: 50%;
    }

    .elementor-4516 .elementor-element.elementor-element-96fb4a4 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-96fb4a4 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-e9d58cf .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-e9d58cf .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-1311674 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-1311674 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-92590fb .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-92590fb .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-29fe131 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-29fe131 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-e4a0690 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-e4a0690 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-2af1818 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-2af1818 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-5c80eb5 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-5c80eb5 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-53a851b .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-53a851b .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-f7af9b4 {
      width: 50%;
    }

    .elementor-4516 .elementor-element.elementor-element-69ff6e6 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-69ff6e6 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-0179afb .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-0179afb .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-1c56b38 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-1c56b38 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-a97436f .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-a97436f .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-5821d4e .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-5821d4e .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-750f328 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-750f328 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-7cdfe24 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-7cdfe24 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-d2601ed .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-d2601ed .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-6232017 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-6232017 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-fc48dbd .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-fc48dbd .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-70bceb3 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-70bceb3 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-f7af63c .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-f7af63c .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-de1df67 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-de1df67 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-dc57470 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-dc57470 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-dc986e4 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-dc986e4 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-a245cd7 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-a245cd7 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-18e1c4b {
      padding: 80px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-7cff8c9 .elementor-heading-title {
      font-size: 36px;
      line-height: 46px;
    }

    .elementor-4516 .elementor-element.elementor-element-7e2d383>.elementor-element-populated {
      margin: 60px 0px 0px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-e12f41f>.elementor-background-overlay {
      background-position: 0px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-e12f41f {
      padding: 80px 0px 80px 0px;
    }

    .elementor-4516 .elementor-element.elementor-element-997b631 .elementor-heading-title {
      font-size: 36px;
      line-height: 46px;
    }

    .elementor-4516 .elementor-element.elementor-element-afce128.elementor-column>.elementor-widget-wrap {
      justify-content: flex-start;
    }

    .elementor-4516 .elementor-element.elementor-element-815d7e3 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-5b4851b {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-4dd791e .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-4dd791e .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-f08157a .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-f08157a .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-6ce7092 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-6ce7092 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-b988522 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-b988522 .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-740effe .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-740effe .icon-box-title {
      line-height: 36px;
    }

    .elementor-4516 .elementor-element.elementor-element-b97a4f2 .ot-icon-box__icon {
      margin-bottom: 10px;
    }

    .elementor-4516 .elementor-element.elementor-element-b97a4f2 .icon-box-title {
      line-height: 36px;
    }
  }

  @media (min-width: 768px) {
    .elementor-4516 .elementor-element.elementor-element-e107369 {
      width: 100%;
      height: 100%;
      
    }

    .elementor-4516 .elementor-element.elementor-element-fd2d84c {
      width: 41.67%;
    }

    .elementor-4516 .elementor-element.elementor-element-9c007dc {
      width: 66.67%;
    }

    .elementor-4516 .elementor-element.elementor-element-3195a2e {
      width: 33.33%;
    }

    .elementor-4516 .elementor-element.elementor-element-7dcd9f5 {
      width: 16.67%;
    }

    .elementor-4516 .elementor-element.elementor-element-afce128 {
      width: 66.67%;
    }

    .elementor-4516 .elementor-element.elementor-element-a35990a {
      width: 16.324%;
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .elementor-4516 .elementor-element.elementor-element-e107369 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-fd2d84c {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-4b45ea9 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-184d506 {
      width: 40%;
    }

    .elementor-4516 .elementor-element.elementor-element-c8f90a0 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-65e0774 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-4402f26 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-5acda1c {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-5d89801 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-9d55318 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-2531999 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-0f5bc19 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-ec09f5b {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-71b2524 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-ba4ddc0 {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-2cd938e {
      width: 100%;
    }

    .elementor-4516 .elementor-element.elementor-element-b929864 {
      width: 80%;
    }

    .elementor-4516 .elementor-element.elementor-element-f9cfb15 {
      width: 100%;
    }
  }
}

/*! CSS Used from: Embedded */
img#wpstats {
  display: none;
}

/*! CSS Used from: Embedded */
@media (max-width: 1220px) {
  .main-navigation>ul>li {
    margin: 0px 12px;
  }

  .main-navigation ul>li>a {
    font-size: 16px;
  }

  .header-desktop a.octf-btn {
    font-size: 14px !important;
    padding: 12px 24px 12px 24px !important;
  }
}

.page-id-4516 #back-to-top {
  background: #4316c4;
}

.page #back-to-top:hover {
  background: #2e2e4d;
  color: #fff;
}

.security-software .mc4wp-form-fields .main-form input[type="email"],
.security-software .mc4wp-form-fields .main-form input[type="submit"] {
  font-family: "Manrope";
}

@media all {
  .elementor-4548 .elementor-element.elementor-element-5fe1eb60 {
    border-radius: 0px 0px 0px 0px;
  }

  .add-margin {
    margin: 20px 20px 0px 20px;
  }

  .elementor-4548 .elementor-element.elementor-element-5fe1eb60 {
    padding: 0px 40px 0px 40px;
  }

  .elementor-4548 .elementor-element.elementor-element-69365872.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }

  .elementor-4548 .elementor-element.elementor-element-69365872 {
    min-width: 170px;
  }

  .elementor-4548 .elementor-element.elementor-element-69365872>.elementor-element-populated {
    margin: 0px 26px 0px 0px;
    --e-column-margin-right: 26px;
    --e-column-margin-left: 0px;
  }

  .elementor-4548 .elementor-element.elementor-element-29fca55 .the-logo img {
    width: 250px;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
  }

  .elementor-4548 .elementor-element.elementor-element-7d018ea8>.elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 {
    text-align: center;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li>a,
  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li.menu-item-has-children>a:after,
  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li.is-mega-menu>a:after {
    color: #0f1a51;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li>a:before {
    background-color: #0f1a51;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li>a:hover,
  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li:hover>a,
  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li:hover>a:after {
    color: #4316c4;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li>a:before {
    background-color: #4316c4;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li>a {
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 600;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li>a:hover:before {
    width: 20px;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul>li>a:before {
    height: 2px;
    bottom: -2px;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul ul:not(.sub-mega-menu, .elementor-icon-list-items, .ot-icon-list-items) a,
  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul ul:not(.sub-mega-menu, .elementor-icon-list-items, .ot-icon-list-items)>li.menu-item-has-children>a:after {
    color: #646e83;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul ul:not(.sub-mega-menu, .elementor-icon-list-items, .ot-icon-list-items) li:hover>a {
    background: #4316c41a;
  }

  .elementor-4548 .elementor-element.elementor-element-35bff668 .main-navigation>ul ul:not(.sub-mega-menu) a {
    font-family: "Manrope", Sans-serif;
    font-size: 16px;
  }

  .elementor-4548 .elementor-element.elementor-element-2e822260.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }

  .elementor-4548 .elementor-element.elementor-element-2e822260.elementor-column>.elementor-widget-wrap {
    justify-content: flex-end;
  }

  .elementor-4548 .elementor-element.elementor-element-2e822260 {
    min-width: 340px;
  }

  .elementor-4548 .elementor-element.elementor-element-2e822260>.elementor-element-populated {
    margin: 0px 0px 0px 20px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 20px;
    padding: 0px 0px 0px 0px;
  }

  .elementor-4548 .elementor-element.elementor-element-d11b1a7 {
    text-align: right;
  }

  .elementor-4548 .elementor-element.elementor-element-d11b1a7 .ot-title-link h5 {
    margin-bottom: 0px;
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
  }

  .elementor-4548 .elementor-element.elementor-element-d11b1a7 .ot-title-link a {
    color: #0f1a51;
  }

  .elementor-4548 .elementor-element.elementor-element-d11b1a7 .ot-title-link a:hover {
    color: #4316c4;
  }

  .elementor-4548 .elementor-element.elementor-element-6697ddc7 .octf-btn {
    padding: 14px 52px 14px 52px;
    border-radius: 25px 25px 25px 25px;
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    fill: #ffffff;
    color: #ffffff;
    background-color: #0f1a51;
  }

  .elementor-4548 .elementor-element.elementor-element-6697ddc7 .octf-btn:hover,
  .elementor-4548 .elementor-element.elementor-element-6697ddc7 .octf-btn:focus {
    background-color: #4316c4;
    border-color: #4316c4;
  }

  .elementor-4548 .elementor-element.elementor-element-6697ddc7>.elementor-widget-container {
    margin: 0px 35px 0px 30px;
  }

  .elementor-4548 .elementor-element.elementor-element-6697ddc7 {
    width: initial;
    max-width: initial;
  }
}

@media all {
  .elementor-8516 .elementor-element.elementor-element-791e5814:not(.elementor-motion-effects-element-type-background) {
    
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .elementor-8516 .elementor-element.elementor-element-791e5814 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 30px 0px 0px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-b265eea:not(.elementor-motion-effects-element-type-background) {
    background-color: #ffffff;
  }

  .elementor-8516 .elementor-element.elementor-element-b265eea {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
  }

  .elementor-8516 .elementor-element.elementor-element-f0870e3 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-f0870e3 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-f0870e3 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-f0870e3 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-f0870e3 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-f0870e3>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-3ba1c07 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-3ba1c07 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-3ba1c07 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-3ba1c07 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-3ba1c07 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-3ba1c07>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-74fef5e figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-74fef5e .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-74fef5e .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-74fef5e .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-74fef5e .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-74fef5e>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-7437fcc figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-7437fcc .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-7437fcc .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-7437fcc .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-7437fcc .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-7437fcc>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-d115fbc figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-d115fbc .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-d115fbc .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-d115fbc .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-d115fbc .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-d115fbc>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-ae57dd2 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-ae57dd2 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-ae57dd2 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-ae57dd2 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-ae57dd2 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-ae57dd2>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-7d06d1f6 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-7d06d1f6 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-7d06d1f6 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-7d06d1f6 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-7d06d1f6 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-7d06d1f6>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-89b48fc figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-89b48fc .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-89b48fc .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-89b48fc .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-89b48fc .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-89b48fc>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-cfafb5e figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-cfafb5e .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-cfafb5e .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-cfafb5e .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-cfafb5e .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-cfafb5e>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-6f7c120 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-6f7c120 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-6f7c120 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-6f7c120 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-6f7c120 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-6f7c120>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-d526809 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-d526809 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-d526809 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-d526809 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-d526809 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-d526809>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-5cf46735 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-5cf46735 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-5cf46735 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-5cf46735 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-5cf46735 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-5cf46735>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-ab4fed0 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-ab4fed0 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-ab4fed0 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-ab4fed0 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-ab4fed0 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-ab4fed0>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-12298c5 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-12298c5 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-12298c5 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-12298c5 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-12298c5 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-12298c5>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-10de8a8 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-10de8a8 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-10de8a8 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-10de8a8 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-10de8a8 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-10de8a8>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-ca65613 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-ca65613 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-ca65613 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-ca65613 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-ca65613 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-ca65613>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-cbc40dc figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-cbc40dc .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-cbc40dc .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-cbc40dc .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-cbc40dc .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-cbc40dc>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-a314628 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-a314628 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-a314628 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-a314628 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-a314628 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-a314628>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-adc2835 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-adc2835 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-adc2835 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-adc2835 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-adc2835 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-adc2835>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-7d9a6e7 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-7d9a6e7 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-7d9a6e7 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-7d9a6e7 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-7d9a6e7 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-7d9a6e7>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-172767f5 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-172767f5 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-172767f5 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-172767f5 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-172767f5 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-172767f5>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-71e7f74 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-71e7f74 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-71e7f74 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-71e7f74 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-71e7f74 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-71e7f74>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-05115cf figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-05115cf .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-05115cf .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-05115cf .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-05115cf .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-05115cf>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-1bc4c23 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-1bc4c23 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-1bc4c23 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-1bc4c23 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-1bc4c23 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-1bc4c23>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }

  .elementor-8516 .elementor-element.elementor-element-ff15f63 figure.ot-image-box__img {
    margin-bottom: 20px;
  }

  .elementor-8516 .elementor-element.elementor-element-ff15f63 .ot-image-box__img img {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .elementor-8516 .elementor-element.elementor-element-ff15f63 .ot-image-box:hover .ot-image-box__img img {
    box-shadow: 9px 9px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .elementor-8516 .elementor-element.elementor-element-ff15f63 .ot-image-box {
    text-align: center;
  }

  .elementor-8516 .elementor-element.elementor-element-ff15f63 .image-box-title {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .elementor-8516 .elementor-element.elementor-element-ff15f63>.elementor-widget-container {
    margin: 0px 0px 35px 0px;
  }
}

@media all {
  .elementor-6110 .elementor-element.elementor-element-3d69f53 {
    padding: 20px 25px 0px 25px;
  }

  .elementor-6110 .elementor-element.elementor-element-1c950ba .the-logo img {
    width: 180px;
  }

  .elementor-6110 .elementor-element.elementor-element-1c950ba>.elementor-widget-container {
    padding: 20px 0px 20px 0px;
  }

  .elementor-6110 .elementor-element.elementor-element-867868d.elementor-column>.elementor-widget-wrap {
    justify-content: flex-end;
  }

  .elementor-6110 .elementor-element.elementor-element-b5e7265 .toggle_search i:hover {
    color: #223354;
  }

  .elementor-6110 .elementor-element.elementor-element-ac41c13>.elementor-widget-container {
    margin: 0px 0px 0px 30px;
  }

  @media (max-width: 767px) {
    .elementor-6110 .elementor-element.elementor-element-f25eb59 {
      width: 50%;
    }

    .elementor-6110 .elementor-element.elementor-element-867868d {
      width: 50%;
    }
  }
}

@media all {
  .elementor-6963 .elementor-element.elementor-element-81a8e69 {
    padding: 120px 0px 0px 0px;
  }

  .elementor-6963 .elementor-element.elementor-element-af02a4e .the-logo img {
    width: 212px;
  }

  .elementor-6963 .elementor-element.elementor-element-af02a4e>.elementor-widget-container {
    margin: 0px 0px 62px 0px;
  }

  .elementor-6963 .elementor-element.elementor-element-c574e44 .elementor-heading-title {
    color: #192032;
    font-family: "Spartan", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .elementor-6963 .elementor-element.elementor-element-c574e44>.elementor-widget-container {
    margin: 0px 0px 20px 0px;
  }

  .elementor-6963 .elementor-element.elementor-element-9946efb {
    color: #192032;
    font-family: "Spartan", Sans-serif;
    font-weight: 500;
    line-height: 36px;
  }

  .elementor-6963 .elementor-element.elementor-element-9946efb>.elementor-widget-container {
    margin: 0px 0px 27px 0px;
  }

  .elementor-6963 .elementor-element.elementor-element-ce5d084 .ot-title-link a {
    color: #ff7241;
  }

  .elementor-6963 .elementor-element.elementor-element-ce5d084 .ot-title-link h5 {
    font-family: "Spartan", Sans-serif;
    font-size: 18px;
    font-weight: 500;
  }

  .elementor-6963 .elementor-element.elementor-element-ce5d084>.elementor-widget-container {
    margin: 0px 0px 36px 0px;
  }

  .elementor-6963 .elementor-element.elementor-element-f162e06 {
    --grid-template-columns: repeat(0, auto);
    --icon-size: 26px;
    --grid-column-gap: 28px;
    --grid-row-gap: 0px;
  }

  .elementor-6963 .elementor-element.elementor-element-f162e06 .elementor-widget-container {
    text-align: left;
  }

  .elementor-6963 .elementor-element.elementor-element-f162e06 .elementor-social-icon {
    background-color: #ffffff00;
    --icon-padding: 0em;
  }

  .elementor-6963 .elementor-element.elementor-element-f162e06 .elementor-social-icon i {
    color: #1920324d;
  }

  .elementor-6963 .elementor-element.elementor-element-f162e06 .elementor-social-icon:hover i {
    color: #ff7241;
  }
}

@media all {
  .elementor-4568 .elementor-element.elementor-element-f80991f {
    padding: 95px 0px 120px 0px;
    
  }

  .elementor-4568 .elementor-element.elementor-element-67e919d8>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
    
  }

  .elementor-4568 .elementor-element.elementor-element-6e5802c3 .elementor-heading-title {
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 1em;
  }

  .elementor-4568 .elementor-element.elementor-element-6e5802c3>.elementor-widget-container {
    margin: 0px 0px 25px 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-7238cdc9 {
    color: #646e83;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }

  .elementor-4568 .elementor-element.elementor-element-63022e05 {
    color: #646e83;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }

  .elementor-4568 .elementor-element.elementor-element-63022e05>.elementor-widget-container {
    padding: 0px 0px 15px 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-3d5fd504 {
    color: #646e83;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }

  .elementor-4568 .elementor-element.elementor-element-3d5fd504>.elementor-widget-container {
    padding: 0px 0px 15px 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-30d9fb18 {
    color: #646e83;
    font-family: "Manrope", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }

  .elementor-4568 .elementor-element.elementor-element-30d9fb18>.elementor-widget-container {
    padding: 0px 30px 20px 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-15d9d7e7 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-15d9d7e7 .elementor-icon-list-item>a {
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4568 .elementor-element.elementor-element-15d9d7e7 .elementor-icon-list-text {
    color: #4316c4;
    transition: color 0.3s;
  }

  .elementor-4568 .elementor-element.elementor-element-15d9d7e7 .elementor-icon-list-item:hover .elementor-icon-list-text {
    color: #0f1a51;
  }

  .elementor-4568 .elementor-element.elementor-element-4e9b33d5>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4568 .elementor-element.elementor-element-55ac18c8 .elementor-heading-title {
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 1em;
  }

  .elementor-4568 .elementor-element.elementor-element-55ac18c8>.elementor-widget-container {
    margin: 0px 0px 25px 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-73a0770 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-73a0770 .elementor-icon-list-item>a {
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4568 .elementor-element.elementor-element-73a0770 .elementor-icon-list-text {
    color: #54595f;
    transition: color 0.3s;
  }

  .elementor-4568 .elementor-element.elementor-element-73a0770 .elementor-icon-list-item:hover .elementor-icon-list-text {
    color: #4316c4;
  }

  .elementor-4568 .elementor-element.elementor-element-61c8f88d>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4568 .elementor-element.elementor-element-3a3e4023 .elementor-heading-title {
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 1em;
  }

  .elementor-4568 .elementor-element.elementor-element-3a3e4023>.elementor-widget-container {
    margin: 0px 0px 25px 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-3c4b9dab {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-3c4b9dab .elementor-icon-list-item>a {
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4568 .elementor-element.elementor-element-3c4b9dab .elementor-icon-list-item:hover .elementor-icon-list-text {
    color: #4316c4;
  }

  .elementor-4568 .elementor-element.elementor-element-3c4b9dab .elementor-icon-list-text {
    transition: color 0.3s;
  }

  .elementor-4568 .elementor-element.elementor-element-29339f4b>.elementor-element-populated {
    padding: 0px 15px 0px 15px;
  }

  .elementor-4568 .elementor-element.elementor-element-4e437857 .elementor-heading-title {
    font-family: "Manrope", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 1em;
  }

  .elementor-4568 .elementor-element.elementor-element-4e437857>.elementor-widget-container {
    margin: 0px 0px 25px 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-44ea3d22 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-44ea3d22 .elementor-icon-list-item>a {
    font-family: "Manrope", Sans-serif;
    font-weight: 400;
  }

  .elementor-4568 .elementor-element.elementor-element-44ea3d22 .elementor-icon-list-item:hover .elementor-icon-list-text {
    color: #4316c4;
  }

  .elementor-4568 .elementor-element.elementor-element-44ea3d22 .elementor-icon-list-text {
    transition: color 0.3s;
  }

  .elementor-4568 .elementor-element.elementor-element-2747cd19>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
  }

  .elementor-4568 .elementor-element.elementor-element-2747cd19:not(.elementor-motion-effects-element-type-background) {
    background-color: #ffffff;
  }

  .elementor-4568 .elementor-element.elementor-element-2747cd19 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 40px 0px 40px 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-34f9566f {
    color: #646e83;
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .elementor-4568 .elementor-element.elementor-element-609027d9 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
  }

  .elementor-4568 .elementor-element.elementor-element-609027d9 .elementor-icon-list-item>a {
    font-family: "Manrope", Sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .elementor-4568 .elementor-element.elementor-element-609027d9 .elementor-icon-list-text {
    color: #646e83;
    transition: color 0.3s;
  }

  .elementor-4568 .elementor-element.elementor-element-609027d9 .elementor-icon-list-item:hover .elementor-icon-list-text {
    color: #223354;
  }

  @media (max-width: 1024px) {
    .elementor-4568 .elementor-element.elementor-element-67e919d8>.elementor-element-populated {
      margin: 0px 0px 40px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4568 .elementor-element.elementor-element-4e9b33d5>.elementor-element-populated {
      margin: 0px 0px 40px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }
  }

  @media (max-width: 767px) {
    .elementor-4568 .elementor-element.elementor-element-f80991f {
      padding: 100px 0px 50px 0px;
    }

    .elementor-4568 .elementor-element.elementor-element-61c8f88d>.elementor-element-populated {
      margin: 0px 0px 40px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }

    .elementor-4568 .elementor-element.elementor-element-2747cd19 {
      padding: 0px 0px 25px 0px;
    }

    .elementor-4568 .elementor-element.elementor-element-7e64685d>.elementor-element-populated {
      margin: 0px 0px 10px 0px;
      --e-column-margin-right: 0px;
      --e-column-margin-left: 0px;
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .elementor-4568 .elementor-element.elementor-element-67e919d8 {
      width: 50%;
    }

    .elementor-4568 .elementor-element.elementor-element-4e9b33d5 {
      width: 50%;
    }

    .elementor-4568 .elementor-element.elementor-element-61c8f88d {
      width: 50%;
    }

    .elementor-4568 .elementor-element.elementor-element-29339f4b {
      width: 50%;
    }
  }
}

@media all {
  .fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }

  .fa-facebook-f:before {
    content: "\f39e";
  }

  .fa-instagram:before {
    content: "\f16d";
  }

  .fa-linkedin-in:before {
    content: "\f0e1";
  }
}

@media all {
  .fab {
    font-family: "Font Awesome 5 Brands";
    font-weight: 400;
  }
}



/*! CSS Used fontfaces */
@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-RaLY50.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-1aLY50.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-ZaLY50.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-daLY50.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-laLQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-RaLY50.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-1aLY50.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-ZaLY50.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-daLY50.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-laLQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-RaLY50.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-1aLY50.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-ZaLY50.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-daLY50.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-laLQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-RaLY50.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-1aLY50.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-ZaLY50.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-daLY50.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-laLQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-RaLY50.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-1aLY50.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-ZaLY50.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-daLY50.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-laLQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-RaLY50.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-1aLY50.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-ZaLY50.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-daLY50.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-laLQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-RaLY50.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-1aLY50.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-ZaLY50.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-daLY50.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0RMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwd-laLQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t4R-tCKQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7txR-tCKQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t6R-tCKQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t7R-tCKQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t4R-tCKQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7txR-tCKQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t6R-tCKQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t7R-tCKQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t4R-tCKQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7txR-tCKQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t6R-tCKQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t7R-tCKQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t4R-tCKQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7txR-tCKQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t6R-tCKQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t7R-tCKQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t4R-tCKQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7txR-tCKQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t6R-tCKQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t7R-tCKQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t4R-tCKQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7txR-tCKQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t6R-tCKQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t7R-tCKQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t4R-tCKQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7txR-tCKQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t6R-tCKQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t7R-tCKQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v15/pe0TMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp7t1R-s.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}





@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Wp2ywxg089UriCZaSExd86J3t9jz86MvyyKK58VXh.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Wp2ywxg089UriCZaSExd86J3t9jz86MvyyKy58Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Wp2ywxg089UriCZaSExd86J3t9jz86MvyyKK58VXh.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Wp2ywxg089UriCZaSExd86J3t9jz86MvyyKy58Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Wp2ywxg089UriCZaSExd86J3t9jz86MvyyKK58VXh.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Wp2ywxg089UriCZaSExd86J3t9jz86MvyyKy58Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu6-K6h9Q.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu6-K6h9Q.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu6-K6h9Q.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}













@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


