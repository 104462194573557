*,
::after,
::before {
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-10,
.col-lg-4,
.col-md-6,
.col-xl-8 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.text-center {
  text-align: center !important;
}

@media print {

  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  img {
    page-break-inside: avoid;
  }

  h2 {
    orphans: 3;
    widows: 3;
  }

  h2 {
    page-break-after: avoid;
  }

  .container {
    min-width: 992px !important;
  }
}


.mb-60 {
  margin-bottom: 60px;
}

.pt-120 {
  padding-top: 120px;
}



button {
  color: #1696e7;
  outline: medium none;
}

button:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

.btn {

  background: #ea500f none repeat scroll 0 0;
  border: medium none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 50px;
  padding: 16px 28px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
}

.btn:hover {
  background: #58468c;
  color: #fff;
  box-shadow: 0px 10px 40px 0px rgba(88, 70, 140, 0.29);
}

.section-title span {
  font-size: 36px;
  text-transform: uppercase;
  color: #ea500f;
  font-weight: 500;
  display: block;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.contact-form input {
  width: 100%;
  border: 1px solid #ebebeb;
  background: #fff;
  font-size: 14px;
  color: #786d99;
  padding: 16px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.contact-form textarea {
  width: 100%;
  border: 1px solid #ebebeb;
  background: #fff;
  font-size: 14px;
  color: #786d99;
  padding: 16px 15px;
  border-radius: 5px;
  margin-bottom: 40px;
  height: 150px;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #786d99;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .contact-wrap {
    padding: 0;
    padding-bottom: 85px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-wrap {
    padding: 0;
    padding-bottom: 85px;
  }
}

@media (max-width: 767px) {
  .section-title h2 {
    font-size: 30px;
  }

  .contact-wrap {
    padding: 0;
    padding-bottom: 85px;
  }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
  .section-title h2 {
    font-size: 36px;
  }

  .contact-wrap {
    padding: 0 50px;
    padding-bottom: 85px;
  }
}